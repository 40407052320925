
import { Component, Vue, Prop } from "vue-property-decorator";
import DOMService from "@/services/DOMService";

declare const google: any;

@Component
export default class HiboGoogleMap extends Vue {
  @Prop({ type: [String, Number], default: 1 })
  protected id?: string | number;

  @Prop({ type: Number, required: true })
  protected latitude!: number;

  @Prop({ type: Number, required: true })
  protected longitude!: number;

  @Prop({ type: Number, default: 17 })
  protected zoom!: number;

  @Prop({ type: String, default: "" })
  protected markerTitle!: string;

  private googleApiKey =
    process.env.VUE_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY_SECRET;
  private map: Record<string, any> = {};

  loadMaps() {
    DOMService.loadJS(
      "googleapis",
      `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.googleApiKey}`,
      () => setTimeout(() => this.initMap())
    );
  }

  initMap() {
    const element = document.getElementById("map" + this.id) as HTMLElement;
    const options = {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: this.zoom,
      options: {
        clickableIcons: false,
        fullscreenControl: false,
        draggable: false,
        mapTypeControl: false,
        streetViewControl: false,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
        ],
        zoomControl: true,
      },
    };

    this.map = new google.maps.Map(element, options);
    new google.maps.Marker({
      position: { lat: this.latitude, lng: this.longitude },
      map: this.map,
      title: this.markerTitle,
      icon: require("@/assets/png/icons/marker.png"),
    });
  }

  mounted() {
    this.loadMaps();
  }
}
