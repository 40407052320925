import { ICookiesConstantsKeys, IStorageConstantKeys } from "@/models/Global";
import Cookies from "js-cookie";

export const KEYS: IStorageConstantKeys = {
  USER_TOKEN: "housfy_api_token",
  USER_UUID: "housfy_user_uuid",
  USER_ID: "housfy_user_id",
  ADMIN_USER_ID: "housfy_admin_user_id",
  ADMIN_USER_UUID: "housfy_admin_user_uuid",
  BROWSER_UUID: "housfy_browser_uuid",
};

export const COOKIES_KEYS: ICookiesConstantsKeys = {
  UTMS: "__utmzz",
};

const cookieOptions = {
  expires: 365,
  samesite: "lax",
  domain: "",
};

if (process.env.NODE_ENV === "production") {
  cookieOptions.domain = `.${document.domain}`;
}

class Storage {
  static keys(): IStorageConstantKeys {
    return KEYS;
  }

  static setItem(itemName: string, value: number | string) {
    localStorage.setItem(itemName, JSON.stringify(value));
  }

  static getItem(itemName: string, fallback?: string | null) {
    try {
      const item = localStorage.getItem(itemName);
      return item ? JSON.parse(item) : fallback;
    } catch (e) {
      return fallback;
    }
  }

  static removeItem(itemName: string) {
    localStorage.removeItem(itemName);
  }

  static getItemSessionStorage(itemName: string, fallback = null) {
    const item = sessionStorage.getItem(itemName);
    return item ? JSON.parse(item) : fallback;
  }

  static getItemCookies(itemName: string) {
    return Cookies.get(itemName);
    Cookies;
  }

  static setItemCookies(
    itemName: string,
    value: string,
    { expires } = { expires: 365 }
  ) {
    Cookies.set(itemName, value, { ...cookieOptions, expires });
    Cookies;
  }
}

export default Storage;
