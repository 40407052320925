
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ISelectOption } from "@/models/Global";

@Component
export default class HiboFloatingDropdown extends Vue {
  @Prop({ type: Array, default: () => [] })
  protected options?: ISelectOption[];

  @Prop({ type: Boolean, default: false })
  protected secondary?: boolean;

  @Prop({ type: String, default: null })
  protected icon?: string;

  @Prop({ type: Boolean, default: false })
  protected loading?: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled?: boolean;

  protected visible = false;

  protected open() {
    if (!this.disabled) this.visible = !this.visible;
  }

  protected close() {
    this.visible = false;
  }

  @Emit("click")
  clicked(option: ISelectOption) {
    return option;
  }

  handleClick(option: ISelectOption) {
    if (!this.disabled) this.clicked(option);
  }
}
