import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MarketingService from "@/services/MarketingService";
import * as Sentry from "@sentry/browser";
import StitchService from "@/services/StitchService";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "authLogin",
    meta: {
      public: true,
    },
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
  },
  {
    path: "/reset-password",
    name: "authResetpassword",
    meta: {
      public: true,
    },
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
  },
  {
    path: "/activate-account",
    name: "authSignup",
    meta: {
      public: true,
    },
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },
  {
    path: "/mortgages",
    name: "mortgages",
    component: () =>
      import(/* webpackChunkName: "mortgages" */ "../views/Mortgages.vue"),
  },
  {
    path: "/supplies",
    name: "supplies",
    component: () =>
      import(/* webpackChunkName: "supplies" */ "../views/Supplies.vue"),
  },
  {
    path: "/tools",
    name: "tools",
    redirect: "/tools/mortgage-simulator",
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/Tools.vue"),

    children: [
      {
        alias: ["/"],
        path: "mortgage-simulator",
        name: "mortgageSimulator",
        component: () =>
          import(/* webpackChunkName: "tools" */ "../views/Tools.vue"),
      },
      {
        path: "appraiser-simulator",
        name: "appraiserSimulator",
        component: () =>
          import(/* webpackChunkName: "tools" */ "../views/Tools.vue"),
      },
    ],
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/Search.vue"),
  },

  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/MyAccount.vue"),

    children: [
      {
        path: "/profile/my-account",
        alias: ["/"],
        name: "myAccount",
        component: () =>
          import(/* webpackChunkName: "profile" */ "../views/MyAccount.vue"),
      },
      {
        path: "/profile/company-data",
        name: "companyData",
        component: () =>
          import(/* webpackChunkName: "profile" */ "../views/MyAccount.vue"),
      },
    ],
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactMobile.vue"),
  },
  {
    path: "/:e404",
    name: "404",
    meta: {
      public: true,
    },
    component: () => import(/* webpackChunkName: "404" */ "../views/Auth.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  MarketingService.analytics().setRoute(to);

  try {
    if (from.name) MarketingService.analytics().pageView(to);
  } catch (err) {
    console.error("PageView not tracked");
    Sentry.captureException("PageView not tracked: " + err);
  }

  try {
    StitchService.trackEvent("page_view");
  } catch (err) {
    console.error("Stitch Error ", err);
  }
});

export default router;
