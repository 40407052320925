import { render, staticRenderFns } from "./TheSidebarDesktop.vue?vue&type=template&id=321f9f05&scoped=true&"
import script from "./TheSidebarDesktop.vue?vue&type=script&lang=ts&"
export * from "./TheSidebarDesktop.vue?vue&type=script&lang=ts&"
import style0 from "./TheSidebarDesktop.vue?vue&type=style&index=0&id=321f9f05&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321f9f05",
  null
  
)

export default component.exports