import { DirectiveOptions } from "vue";

const focusDirective: DirectiveOptions = {
  inserted(el, { value }) {
    if (value) {
      el.focus();
    }
  },
};

export default focusDirective;
