import Vue from "vue";
import Vuex from "vuex";
import {
  IUser,
  IUserLoginCredentials,
  IUserSignUpCredentials,
  IUserForgottenPassResetCredentials,
  IUserForgottenPassRequestCredentials,
  IUserDataToUpdate,
  IUserAvatarUploadData,
} from "@/models/User";
import { IAuthState, IActionContext } from "@/models/Store";
import { IFranchise, ILoginData } from "@/models/Auth";
import { IApiResponse } from "@/models/Global";
import api from "@/api";
import Storage from "@/plugins/storage";
import { v4 as uuidv4 } from "uuid";
import ArrayService from "@/services/ArrayService";
import { BUSINESS } from "@/constants";
import { MASTERS_FRANCHISES, ALBROKSA_MASTER_ID } from "@/constants";

Vue.use(Vuex);

const state: IAuthState = {
  user: {},
  initialLoading: true,
  useDashboard: true,
  useResumePlots: true,
  business: [BUSINESS.mortgages],
  franchises: [],
};

export default {
  namespaced: true,
  state,
  mutations: {
    FINISH_INITIAL_LOADING: (state: IAuthState) => {
      state.initialLoading = false;
    },
    SET_USER: (state: IAuthState, user: IUser) => {
      const isSalesman = user.parentId
        ? !MASTERS_FRANCHISES.includes(user.parentId)
        : false;
      state.useResumePlots = !isSalesman;
      state.user.id = user.housfyAccountId;
      state.user = { ...state.user, ...user };
      state.user.token = Storage.getItem(Storage.keys().USER_TOKEN);
    },
    SET_LOGIN_DATA(state: IAuthState, logindata: ILoginData) {
      state.user.id = logindata.housfyAccountId;
      state.user.token = logindata.token;
      api.setToken(state.user.token);
    },
    SET_LOGOUT_DATA(state: IAuthState) {
      state.user = {};
      api.resetToken();
      Storage.removeItem(Storage.keys().USER_UUID);
    },
    SET_BUSINESS(state: IAuthState) {
      const isFranchiseHousfyAccount = state.user.housfyAccountId
        ? MASTERS_FRANCHISES.includes(state.user.housfyAccountId)
        : false;
      const isFranchiseChild = state.user.parentId
        ? MASTERS_FRANCHISES.includes(state.user.parentId)
        : false;
      if (
        (!isFranchiseHousfyAccount && !isFranchiseChild) ||
        state.user.hasSuppliesAccess
      )
        state.business.push(BUSINESS.supplies);
    },
    SET_FRANCHISES(state: IAuthState, franchises: IFranchise[]) {
      state.franchises = franchises;
    },
  },
  actions: {
    init: async ({ commit, dispatch }: IActionContext) => {
      const token = Storage.getItem(Storage.keys().USER_TOKEN);
      if (token && token.length) {
        const uuid = Storage.getItem(Storage.keys().USER_UUID, uuidv4());
        if (uuid) api.setUuid(uuid);
        api.setToken(token);
        await dispatch("retrieveProfile");
        await dispatch(
          "mortgages/getUnreadMessagesMortgages",
          {},
          { root: true }
        );
        await dispatch("appraiser/retrieveAppraiserState", {}, { root: true });
        commit("FINISH_INITIAL_LOADING");
      } else commit("FINISH_INITIAL_LOADING");
    },

    login: async (
      { commit, dispatch }: IActionContext,
      payload: IUserLoginCredentials
    ): Promise<IApiResponse> => {
      try {
        const loginData: ILoginData = await api.auth().login(payload);
        commit("SET_LOGIN_DATA", loginData);
        await dispatch("retrieveProfile");
        commit("FINISH_INITIAL_LOADING");
        return { success: true, res: loginData };
      } catch (err) {
        console.error(err);
        dispatch("logout");
        return { success: false, res: err };
      }
    },

    autologin: async (
      { commit, dispatch }: IActionContext,
      payload: Record<string, string>
    ) => {
      try {
        const loginData: ILoginData = await api.auth().autologin(payload.token);
        commit("SET_LOGIN_DATA", loginData);
        await dispatch("retrieveProfile");
        await dispatch(
          "mortgages/getUnreadMessagesMortgages",
          {},
          { root: true }
        );
        commit("FINISH_INITIAL_LOADING");
        return true;
      } catch (err) {
        console.error(err);
        dispatch("logout");
        return false;
      }
    },

    createAccount: async (
      { dispatch }: IActionContext,
      payload: IUserSignUpCredentials
    ): Promise<IApiResponse> => {
      try {
        await api.auth().setUpPasswordAccount(payload);
        return { success: true };
      } catch (err) {
        console.error(err);
        dispatch("logout");
        return { success: false, res: err };
      }
    },

    retrieveProfile: async ({ commit, dispatch }: IActionContext) => {
      try {
        const token = Storage.getItem(Storage.keys().USER_TOKEN);
        if (token && token.length) {
          const userData: IUser = await api.auth().retrieveProfile();
          commit("SET_USER", userData);
          commit("SET_BUSINESS");
          if (state.user.isMaster) await dispatch("retrieveFranchises");
          if (userData.uuid) {
            api.setUuid(userData.uuid);
            Storage.setItem(Storage.keys().USER_UUID, userData.uuid);
          }
          const isAlbroksa =
            userData.housfyAccountId === ALBROKSA_MASTER_ID ||
            userData.parentId === ALBROKSA_MASTER_ID;
          await dispatch("dashboard/setIsAlbroksa", isAlbroksa, { root: true });
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    },

    logout: ({ commit, dispatch }: IActionContext) => {
      try {
        dispatch("mortgages/clearUnreadMessagesInterval", {}, { root: true });
        commit("SET_LOGOUT_DATA");
      } catch (err) {
        console.error(err);
        return false;
      }
    },

    resetPassword: async (
      _context: IActionContext,
      payload: IUserForgottenPassResetCredentials
    ): Promise<IApiResponse> => {
      try {
        await api.auth().resetPassword(payload);
        return { success: true };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      }
    },
    requestResetPassword: async (
      _context: IActionContext,
      payload: IUserForgottenPassRequestCredentials
    ): Promise<IApiResponse> => {
      try {
        await api.auth().forgotPassword(payload);
        return { success: true };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      }
    },
    profileUpdatePassword: async (
      _context: IActionContext,
      payload: Record<string, string | null>
    ) => {
      try {
        await api.auth().updatePassword(payload);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },

    profileUpdateData: async (
      { dispatch }: IActionContext,
      payload: IUserDataToUpdate
    ) => {
      try {
        await api.auth().updateUserProfile(payload);
        await dispatch("retrieveProfile");
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },

    profileUpdateAvatar: async (
      { dispatch }: IActionContext,
      payload: IUserAvatarUploadData
    ): Promise<IApiResponse> => {
      try {
        if (payload.file.type.includes("image")) {
          await api.auth().updateUserAvatar(payload);
          await dispatch("retrieveProfile");
          return { success: true };
        } else return { success: false, resTextKey: "fileTypeNotImage" };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      }
    },
    retrieveFranchises: async ({
      commit,
    }: IActionContext): Promise<IApiResponse> => {
      try {
        let franchises: IFranchise[] =
          (await api.auth().retrieveFranchises()) || [];

        franchises = ArrayService.sortBy(franchises, "firstName");

        commit("SET_FRANCHISES", franchises);
        return { success: false };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      }
    },
  },
  getters: {
    getUser: (state: IAuthState) => state.user,
    authenticated: (state: IAuthState) => !!state.user.token,
    initialLoading: (state: IAuthState) => state.initialLoading,
    getBusiness: (state: IAuthState) => state.business,
    useDashboard: (state: IAuthState) => state.useDashboard,
    useResumePlots: (state: IAuthState) => state.useResumePlots,
    getFranchises: (state: IAuthState) => state.franchises,
  },
};
