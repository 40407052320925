import Vue from "vue";
import Vuex from "vuex";
import { IActionContext, ISuppliesState } from "@/models/Store";
import {
  INewSuppliesRequest,
  INewSuppliesResponse,
  ISuppliesCallParams,
  ISuppliesData,
  ISuppliesDataList,
  ISuppliesDataListSettings,
} from "@/models/Supplies";
import api from "@/api";
import { IApiResponse, IFilterSettings, IPagination } from "@/models/Global";
import HiboConfig from "@/services/HiboConfig";
import { SUPPLIES_FILTERS_SORT_KEYS } from "@/constants/supplies";
import { IDocument, IDocumentUploadPayload } from "@/models/Documents";
import { ISuppliesComment } from "@/models/Supplies";

Vue.use(Vuex);

const state: ISuppliesState = {
  operations: [],
  suppliesLoading: false,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    sortField: SUPPLIES_FILTERS_SORT_KEYS.LEAD_ID.sort,
    sortDirection: "desc",
    totalPages: 0,
    totalItems: 0,
    countryIsoCode: HiboConfig.country.toUpperCase(),
  },
};

export default {
  namespaced: true,
  state,
  mutations: {
    START_SUPPLIES_LOADING: (state: ISuppliesState) => {
      state.suppliesLoading = true;
    },
    FINISH_SUPPLIES_LOADING: (state: ISuppliesState) => {
      state.suppliesLoading = false;
    },
    SET_OPERATIONS(state: ISuppliesState, operations: ISuppliesData[]) {
      state.operations = operations;
    },
    SET_PAGINATION(state: ISuppliesState, pagination: IPagination) {
      state.pagination = { ...state.pagination, ...pagination };
    },
  },
  actions: {
    retrieveOperations: async (
      { commit }: IActionContext,
      payload: ISuppliesDataListSettings
    ) => {
      try {
        commit("START_SUPPLIES_LOADING");

        if (payload) commit("SET_PAGINATION", payload.pagination);

        const params: ISuppliesCallParams = {
          page: state.pagination.page,
          itemsPerPage: state.pagination.itemsPerPage,
          sortField: state.pagination.sortField,
          sortDirection: state.pagination.sortDirection,
          countryIsoCode: state.pagination.countryIsoCode,
        };

        if (payload && payload.filters)
          payload.filters?.forEach((filter: IFilterSettings) => {
            if (!filter.value) return;
            if (filter.name === SUPPLIES_FILTERS_SORT_KEYS.FRANCHISE.filter)
              params.franchises = [filter.value as number];
            if (filter.name === SUPPLIES_FILTERS_SORT_KEYS.STATUS.filter) {
              let status_list: number[] = [];
              if (filter.value) status_list = filter.value as number[];
              if (status_list.includes(3)) status_list.push(4);
              params.status = status_list;
            }
            if (filter.name === SUPPLIES_FILTERS_SORT_KEYS.LEAD_ID.filter)
              params.leadId = filter.value as number;
          });

        const operationsList: ISuppliesDataList = await api
          .supplies()
          .getOperations(params);

        commit("SET_OPERATIONS", operationsList.items);
        commit("SET_PAGINATION", {
          page: operationsList.page,
          itemsPerPage: operationsList.itemsPerPage,
          totalItems: operationsList.totalItems,
          totalPages: operationsList.totalPages,
          openOperations: operationsList.openOperations,
        });
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      } finally {
        commit("FINISH_SUPPLIES_LOADING");
      }
    },

    createOperation: async (
      { commit, dispatch }: IActionContext,
      payload: INewSuppliesRequest
    ): Promise<IApiResponse> => {
      try {
        commit("START_SUPPLIES_LOADING");

        const newOperationData: INewSuppliesResponse = await api
          .supplies()
          .createOperation(payload);
        return { success: true, res: newOperationData };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      } finally {
        await dispatch("retrieveOperations");
        commit("FINISH_SUPPLIES_LOADING");
      }
    },
    getSupplyFiles: async (
      { commit }: IActionContext,
      supplyId: number
    ): Promise<IDocument[]> => {
      try {
        commit("START_SUPPLIES_LOADING");
        const result: IDocument[] = await api
          .supplies()
          .getSupplyFiles(supplyId);

        return result;
      } catch (err) {
        console.error(err);
        return [];
      } finally {
        commit("FINISH_SUPPLIES_LOADING");
      }
    },
    getUploadDocUrl: async (
      { commit }: IActionContext,
      payload: IDocumentUploadPayload
    ): Promise<string> => {
      try {
        commit("START_SUPPLIES_LOADING");
        const result: string = await api
          .supplies()
          .uploadDocUrl(payload.operationId, payload.documentId);
        return result;
      } catch (err) {
        console.error(err);
        return "";
      } finally {
        commit("FINISH_SUPPLIES_LOADING");
      }
    },
    getSupplyComments: async (
      { commit }: IActionContext,
      supplyId: number
    ): Promise<ISuppliesComment[]> => {
      try {
        commit("START_SUPPLIES_LOADING");
        return await api.supplies().getSupplyComments(supplyId);
      } catch (err) {
        console.error(err);
        return [];
      } finally {
        commit("FINISH_SUPPLIES_LOADING");
      }
    },
  },
  getters: {
    suppliesLoading: (state: ISuppliesState) => state.suppliesLoading,
    getOperations: (state: ISuppliesState) => state.operations,
    getPagination: (state: ISuppliesState) => state.pagination,
  },
};
