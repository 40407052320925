// Hotjar Tracking Code for hiboteca.com

import HiboConfig from "@/services/HiboConfig";

if (document && HiboConfig.config.IS_HOTJAR_VISIBLE) {
  const script = document.createElement("script");
  script.id = "hotjar";
  script.defer = true;
  script.innerHTML = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2544154,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`;

  document.body.appendChild(script);
}
