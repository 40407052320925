import { ISelectOption, IStatusFilter, ITh } from "@/models/Global";
import {
  IApiMortgagesConstantStatusList,
  IMortgageClient,
  IMortgagesConstantStatusLists,
  IMortgagesConstantStatusListsIds,
  IMortgagesFiltersSortKeys,
} from "@/models/Mortgages";
import { DEFAULT_PHONE } from ".";

export const OPERATION_STATUS_LIST: IMortgagesConstantStatusLists =
  Object.freeze({
    CALL_WITH_AGENT: "callWithAgent",
    DOCUMENTATION: "documentation",
    PERSONALIZE: "mortgagePersonalize",
    BANK_APPLICATION: "bankApplication",
    VALUATION: "valuation",
    SIGNATURE: "operationInFein",
    OPERATION_CHARGE: "completedFile",
    WON: "signAtNotary",
    LOST: "lost_f",
  });

export const OPERATION_STATUS_LIST_ID: IMortgagesConstantStatusListsIds =
  Object.freeze({
    CALL_WITH_AGENT: 1,
    DOCUMENTATION: 2,
    PERSONALIZE: 3,
    BANK_APPLICATION: 4,
    VALUATION: 5,
    SIGNATURE: 6,
    OPERATION_CHARGE: 7,
    WON: 8,
    LOST: 9,
  });

export const MORTGAGES_STATUS: IStatusFilter[] = [
  {
    id: OPERATION_STATUS_LIST.CALL_WITH_AGENT,
    name: "callWithAgent",
    icon: "icons/roundedExcl",
    statusId: OPERATION_STATUS_LIST_ID.CALL_WITH_AGENT,
  },
  {
    id: OPERATION_STATUS_LIST.DOCUMENTATION,
    name: "documentation",
    icon: "icons/roundedExcl",
    statusId: OPERATION_STATUS_LIST_ID.DOCUMENTATION,
  },
  {
    id: OPERATION_STATUS_LIST.PERSONALIZE,
    name: "mortgagePersonalize",
    icon: "icons/roundedExcl",
    statusId: OPERATION_STATUS_LIST_ID.PERSONALIZE,
  },
  {
    id: OPERATION_STATUS_LIST.BANK_APPLICATION,
    name: "bankApplication",
    icon: "icons/roundedExcl",
    statusId: OPERATION_STATUS_LIST_ID.BANK_APPLICATION,
  },
  {
    id: OPERATION_STATUS_LIST.VALUATION,
    name: "valuation",
    icon: "icons/roundedExcl",
    statusId: OPERATION_STATUS_LIST_ID.VALUATION,
  },
  {
    id: OPERATION_STATUS_LIST.SIGNATURE,
    name: "operationInFein",
    icon: "icons/roundedExcl",
    statusId: OPERATION_STATUS_LIST_ID.SIGNATURE,
  },
  {
    id: OPERATION_STATUS_LIST.OPERATION_CHARGE,
    name: "completedFile",
    icon: "icons/roundedExcl",
    statusId: OPERATION_STATUS_LIST_ID.OPERATION_CHARGE,
  },
  {
    id: OPERATION_STATUS_LIST.WON,
    name: "won_f",
    icon: "icons/roundedExcl",
    statusId: OPERATION_STATUS_LIST_ID.WON,
  },
  {
    id: OPERATION_STATUS_LIST.LOST,
    name: "lost_f",
    icon: "icons/roundedExcl",
    statusId: OPERATION_STATUS_LIST_ID.LOST,
  },
];

export const API_MORTGAGES_STATUS_KEY: IApiMortgagesConstantStatusList =
  Object.freeze({
    POSTPONED: "postponed",
    PENDING_PROPERTY: "pending_property",
  });

export const API_MORTGAGES_STATUS_LABEL: IApiMortgagesConstantStatusList =
  Object.freeze({
    POSTPONED: "postponed",
    PENDING_PROPERTY: "pendingProperty",
  });

export const DEFAULT_MORTGAGE_CLIENT: IMortgageClient = {
  name: "",
  lastName: "",
  email: "",
  phone: DEFAULT_PHONE,
  extraInfo: "",
  conditions: false,
  countryIsoCode: "ES",
  simulator: false,
};

export const FILTER_STATUS_OPTIONS: ISelectOption[] = [
  { label: "all", name: "all", value: null },
  ...MORTGAGES_STATUS.map((status) => {
    return {
      label: status.name,
      name: status.name,
      value: status.statusId,
    };
  }),
];

export const MORTGAGES_FILTERS_SORT_KEYS: IMortgagesFiltersSortKeys = {
  LEAD_ID: {
    filter: "leadId",
    sort: "mortgage.mortgage_id",
  },
  CLIENT_NAME: {
    filter: "client",
    sort: "u.first_name",
  },
  CREATED_AT: {
    filter: "created_at",
    sort: "mortgage.created_at",
  },
  STATUS: {
    filter: "status",
    sort: "mortgage.status",
  },
  FRANCHISE: {
    filter: "franchises",
    sort: "franchises",
  },
  BROKER: {
    filter: "broker",
    sort: "broker",
  },
};

export const LIST_TH: ITh[] = [
  {
    id: MORTGAGES_FILTERS_SORT_KEYS.LEAD_ID.sort,
    titleKey: "leadId",
    sortDirection: "desc",
    sortable: true,
  },
  {
    id: MORTGAGES_FILTERS_SORT_KEYS.CLIENT_NAME.sort,
    titleKey: "clientName",
    sortDirection: "desc",
    sortable: true,
  },
  {
    id: MORTGAGES_FILTERS_SORT_KEYS.STATUS.sort,
    titleKey: "status",
    sortDirection: "desc",
    sortable: true,
  },
  {
    id: MORTGAGES_FILTERS_SORT_KEYS.BROKER.sort,
    titleKey: "broker",
    sortDirection: "desc",
    sortable: false,
  },
];

export const LIST_TH_MS: ITh[] = [
  {
    id: MORTGAGES_FILTERS_SORT_KEYS.FRANCHISE.sort,
    titleKey: "salesperson",
    sortDirection: "desc",
    sortable: false,
  },
];

export const LIST_TH_MF: ITh[] = [
  {
    id: MORTGAGES_FILTERS_SORT_KEYS.FRANCHISE.sort,
    titleKey: "franchise",
    sortDirection: "desc",
    sortable: false,
  },
];
