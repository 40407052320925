import HiboRequest from "./request";
import { IResumePlotsResponse } from "@/models/Plots";

export default class DashboardApi {
  private request: HiboRequest;
  private baseUrl = "/hibo/v1/dashboard";
  constructor(request: HiboRequest) {
    this.request = request;
  }

  getResumePlots(): Promise<IResumePlotsResponse> {
    const url = `${this.baseUrl}/info`;
    return this.request.call<IResumePlotsResponse>({ method: "GET", url });
  }
}
