import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { version } from "../../package.json";

if (process.env.VUE_APP_ENVIRONMENT !== "local") {
  const dsnEnv =
    process.env.VUE_APP_ENVIRONMENT === "production"
      ? "https://14e027deb75d4f57922d3822bae79fe3@o91045.ingest.sentry.io/5753414"
      : "https://2d60ed66f0664615babc94fd90eedca3@o91045.ingest.sentry.io/5956363";

  Sentry.init({
    Vue,
    dsn: dsnEnv,
    maxBreadcrumbs: 50,
    release: `hibo-agency-${version}`,
    environment: process.env.VUE_APP_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
