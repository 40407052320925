
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HiboSelect from "@/components/ui/HiboSelect.vue";
import HiboSvg from "@/components/ui/HiboSvg.vue";
import internationalPrefixes from "./data/internationalPrefixes";
import {
  formatPhone,
  validatePhone,
  inputOnlyNumbers,
} from "@/services/FormService";
import { IPhone } from "@/models/Global";

@Component({
  components: { HiboSelect, HiboSvg },
})
export default class HiboTelInput extends Vue {
  @Prop({ type: String, default: null })
  protected id?: string;
  @Prop({ type: String, default: null })
  protected placeholder?: string;
  @Prop({ type: String, default: null })
  protected label?: string;
  @Prop({ type: [Object, String], default: null })
  protected value?: IPhone | string | null;
  @Prop({ type: String, default: null })
  protected errorText?: string;
  @Prop({ type: Boolean, default: false })
  protected validationError?: boolean;
  @Prop({ type: Boolean, default: false })
  protected autofocus?: boolean;
  @Prop({ type: Boolean, default: false })
  protected silent?: boolean;
  @Prop({ type: Boolean, default: false })
  protected withIcon?: boolean;
  protected phone: IPhone | null | string = null;
  protected prefix = "+34";
  protected validPhone = true;
  protected internationalPrefixes = internationalPrefixes.map((prefix) => ({
    ...prefix,
    selectedLabel: prefix.value,
    label: `${prefix.name} ${prefix.value}`,
    value: prefix.value,
  }));

  @Watch("value", { immediate: true })
  handlerValue(val: string) {
    if (!this.silent) this.parsePhone(val);
  }
  @Watch("prefix", { immediate: true })
  handlerPrefix(val: string) {
    this.prefix = val;
    if (typeof this.phone === "string" || !this.phone)
      this.checkValid(this.phone || "", true);
  }

  protected checkValid(value: string, forceParse = false) {
    const phone = value ? value.replace(/\D/g, "") : "";

    this.validPhone = validatePhone(this.prefix, phone) || false;

    const nationalPhone = phone;
    const internationalPhone = `${this.prefix}${nationalPhone}`;
    this.$emit("input", {
      national: nationalPhone,
      phone: internationalPhone,
      internationalPhone: internationalPhone,
      e164: internationalPhone,
      isValid: this.validPhone,
    });

    if (this.phone !== phone || !!forceParse) this.parsePhone(phone);
  }
  protected parsePhone(phone: string | IPhone) {
    if (typeof phone === "string") {
      const tel = phone as string;
      this.phone = formatPhone(this.prefix, tel);
    } else {
      const tel = phone as IPhone;
      if (tel?.national) formatPhone(this.prefix, tel?.national.toString());
    }
  }

  protected handleChange(event: any) {
    this.checkValid(event.target.value);
  }

  protected validateInputChar(event: KeyboardEvent) {
    if (!inputOnlyNumbers(event)) event.preventDefault();
  }
}
