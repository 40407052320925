import HiboRequest from "./request";
import {
  IAppraiserCadastralInfoParams,
  IAppraiserCadastralInfoResponse,
  IAppraiserDataList,
  IAppraiserNewValuationRequest,
  IAppraiserStateResponse,
} from "@/models/Appraiser";
import { IPagination } from "@/models/Global";
import StitchService from "@/services/StitchService";

export default class AppraiserApi {
  private request: HiboRequest;
  private baseUrl = "/hibo/v1";
  constructor(request: HiboRequest) {
    this.request = request;
  }

  getCadastralInfo(
    params: IAppraiserCadastralInfoParams
  ): Promise<IAppraiserCadastralInfoResponse> {
    const url = `${this.baseUrl}/cadastral-info`;
    return this.request.call({ url, method: "GET", params });
  }

  async createValuation(data: IAppraiserNewValuationRequest): Promise<number> {
    const url = `${this.baseUrl}/valuation`;
    const response = await this.request.call<number>({
      method: "POST",
      url,
      data,
    });
    if (response) StitchService.trackEvent("lead", `RE_${response}`);
    return response;
  }

  updateValuation(valuationId: number): Promise<void> {
    const url = `${this.baseUrl}/valuation`;
    const data = { valuationId: valuationId };
    return this.request.call<void>({
      method: "PATCH",
      url,
      data,
    });
  }

  deleteValuation(valuationId: number): Promise<void> {
    const url = `${this.baseUrl}/valuation`;
    const data = { valuationId: valuationId };
    return this.request.call<void>({
      method: "DELETE",
      url,
      data,
    });
  }

  getValuations(params: IPagination): Promise<IAppraiserDataList> {
    const url = `${this.baseUrl}/valuations`;
    return this.request.call<IAppraiserDataList>({
      method: "GET",
      url,
      params,
    });
  }

  createCrossSellLead(valuationId: number): Promise<void> {
    const url = `${this.baseUrl}/valuations/${valuationId}/cross-sell`;
    return this.request.call<void>({
      method: "POST",
      url,
    });
  }

  getAppraiserState(): Promise<IAppraiserStateResponse> {
    const url = `${this.baseUrl}/valuations/config`;
    return this.request.call<IAppraiserStateResponse>({
      method: "GET",
      url,
    });
  }
}
