
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { required, maxLength, sameAs } from "vuelidate/lib/validators";
import { Validations } from "vuelidate-property-decorators";

@Component({})
export default class AuthPasswordInput extends Vue {
  protected password = "";
  protected passwordConfirmation = "";
  protected showPassword1 = false;
  protected showPassword2 = false;

  @Prop({ type: Boolean, default: false })
  protected withConfirmation?: boolean;

  @Prop({ type: Boolean, default: false })
  protected withLabel?: boolean;

  @Prop({ type: Boolean, default: false })
  protected with2labels?: boolean;

  @Prop({ type: Number, default: 20 })
  protected inputsSpacing?: number;

  @Prop({ type: Boolean, default: false })
  protected showAllFormErrors?: boolean;

  @Validations()
  validations = {
    password: { required, maxLength: maxLength(100) },
    passwordConfirmation: {
      sameAsPassword: this.withConfirmation && sameAs("password"),
    },
  };

  @Emit("input")
  handleChange() {
    return !this.$v.$invalid ? this.password : null;
  }
}
