
import { Component, Vue } from "vue-property-decorator";
import AuthPasswordInput from "@/components/auth/AuthPasswordInput.vue";
import { required, email } from "vuelidate/lib/validators";
import { Validations } from "vuelidate-property-decorators";
import { IUserSignUpCredentials } from "@/models/User";
import NotificationModal from "@/components/common/NotificationModal.vue";
import { IApiResponse } from "@/models/Global";
import { mapGetters } from "vuex";
import StitchService from "@/services/StitchService";

@Component({
  computed: {
    ...mapGetters("auth", {
      useDashboard: "useDashboard",
      getBusiness: "getBusiness",
    }),
  },
  components: { AuthPasswordInput, NotificationModal },
})
export default class SignUpForm extends Vue {
  protected useDashboard!: boolean;
  protected getBusiness!: string[];

  protected modalOpen = false;
  protected email = (this.$route?.query?.email as string) || "";
  protected password = "";
  protected apiResponse: IApiResponse | null = null;
  protected formSuccess = false;
  protected showAllFormErrors = false;
  protected prefilled = !!this.$route?.query?.email;

  get token(): string {
    return this.$route && this.$route.query && this.$route.query.token
      ? (this.$route.query.token as string)
      : "";
  }

  async logIn() {
    const payload = {
      email: this.email,
      password: this.password,
    };
    await this.$store.dispatch("auth/login", payload);
    const destinationRoute = this.useDashboard
      ? "dashboard"
      : this.getBusiness[0];
    if (this.$route.path !== destinationRoute)
      await this.$router.push({
        name: destinationRoute,
      });
  }

  async submitForm() {
    if (this.$v.$invalid) {
      this.showAllFormErrors = true;
      return;
    }
    this.apiResponse = await this.signUp();
    if (this.token) {
      this.formSuccess = this.apiResponse.success;
    }

    this.modalOpen = true;
    if (this.apiResponse.success) StitchService.trackEvent("login_successful");
    else StitchService.trackEvent("signup_unsuccessful");
  }

  async signUp(): Promise<IApiResponse> {
    const payload: IUserSignUpCredentials = {
      email: this.email,
      password: this.password,
      token: this.token,
    };
    return await this.$store.dispatch("auth/createAccount", payload);
  }

  mounted() {
    if (this.prefilled) this.$v.email.$touch();
  }

  @Validations()
  validations = {
    email: { required, email },
    password: { required },
  };
}
