
import { Component, Vue, Prop } from "vue-property-decorator";
import { IRadioItems, IInputColors } from "@/models/Global";
import VueI18n from "vue-i18n";

@Component
export default class HiboRadioButton extends Vue {
  @Prop({ type: Array, default: {} })
  protected items?: IRadioItems[];

  @Prop({ type: [Number, String, Boolean], default: null })
  protected value?: number | string | boolean;

  @Prop({ type: Boolean, default: false })
  protected horizontal?: boolean;

  @Prop({ type: Boolean, default: false })
  protected tags?: boolean;

  @Prop({ type: Boolean, default: false })
  protected tagsSquare?: boolean;

  @Prop({ type: Boolean, default: false })
  protected translate?: boolean;

  @Prop({ type: Boolean, default: false })
  protected showDescription?: boolean;

  @Prop({ type: Boolean, default: false })
  protected iconless?: boolean;

  @Prop({ type: Boolean, default: false })
  protected withoutContainer?: boolean;

  @Prop({ type: String, default: null })
  protected dataTracking?: string;

  set localValue(val: string | number | boolean) {
    this.$emit("input", val);
  }

  get localValue(): string | number | boolean {
    if (this.value === undefined) return "";
    return this.value;
  }

  get inputColors(): IInputColors {
    return {
      "--before": "#FF5A54",
      "--after": "#FF5A54",
    };
  }

  safeTranslate(item: string): VueI18n.TranslateResult | string {
    return this.translate ? this.$t(item) : item;
  }
}
