import { ISelectOption, ITab, ITh } from "@/models/Global";
import {
  IAppraiserPropertyCharacteristicsList,
  IAppraiserPropertyIssuesList,
  IAppraiserPropertyStatusList,
  IAppraiserPropertyTypesList,
  IAppraiserRefurbishmentTypesList,
  IAppraiserStepList,
  IAppraiserValuationBannerTypeList,
} from "@/models/Appraiser";

export const TOOL_TABS: ITab[] = [
  {
    name: "mortgageSimulator",
    label: "mortgageSimulator",
    component: "MortgageSimulator",
    selected: false,
    routeName: "mortgageSimulator",
    available: true,
  },
  {
    name: "appraiserSimulator",
    label: "appraiserSimulator",
    component: "AppraiserSimulator",
    selected: false,
    routeName: "appraiserSimulator",
    available: true,
  },
];

export const TOOL_OPTIONS: ISelectOption[] = [
  {
    label: "simulateMortgage",
    value: "mortgageSimulator",
  },
  {
    label: "evaluateProperty",
    value: "appraiserSimulator",
  },
];

export const LIST_TH: ITh[] = [
  {
    id: "date",
    titleKey: "date",
    sortDirection: "desc",
    sortable: false,
  },
  {
    id: "address",
    titleKey: "address",
    sortDirection: "desc",
    sortable: false,
  },
  {
    id: "neighborhoodAndCity",
    titleKey: "neighborhoodAndCity",
    sortDirection: "desc",
    sortable: false,
  },
  {
    id: "appraisal",
    titleKey: "appraisal",
    sortDirection: "desc",
    sortable: false,
  },
  {
    id: "actions",
    titleKey: "actions",
    sortDirection: "desc",
    sortable: false,
  },
];

export const APPRAISER_FORM_STEPS: IAppraiserStepList = Object.freeze({
  PROPERTY_LOCATION: 1,
  PROPERTY_UNIT: 2,
  PROPERTY_EXTRA_DATA: 3,
  RESULT: 4,
});

export const APPRAISER_FORM_PROPERTY_TYPE_IDS: IAppraiserPropertyTypesList =
  Object.freeze({
    PENTHOUSE: 1,
    FLAT: 2,
    DUPLEX: 3,
    HOME: 4,
    LOW: 5,
    OFFICE: 6,
    LOCAL: 7,
    GARAGE: 8,
    PROPERTY: 9,
    SOLAR: 10,
    BUILDING: 11,
  });

export const APPRAISER_FORM_PROPERTY_STATUS: IAppraiserPropertyStatusList =
  Object.freeze({
    BAD: "bad",
    NORMAL: "normal",
    GOOD: "good",
    EXCELLENT: "excellent",
  });

export const APPRAISER_FORM_PROPERTY_CHARACTERISTICS: IAppraiserPropertyCharacteristicsList =
  Object.freeze({
    HAS_EXTERIOR: { label: "exterior", key: "hasExterior" },
    HAS_GARDEN: { label: "garden", key: "hasGarden" },
    HAS_COMMUNAL_POOL: { label: "communalPool", key: "hasCommunalPool" },
    HAS_PRIVATE_POOL: { label: "privatePool", key: "hasPrivatePool" },
    HAS_GARAGE: { label: "garage", key: "hasGarage" },
    HAS_SPORTS_AREA: { label: "sportsArea", key: "hasSportsArea" },
    HAS_TERRACE: { label: "terrace", key: "hasTerrace" },
    HAS_BALCONY: { label: "balcony", key: "hasBalcony" },
    HAS_YARD: { label: "yard", key: "hasYard" },
    HAS_STORAGE_ROOM: { label: "storageRoom", key: "hasStorageRoom" },
    HAS_AIR_CONDITIONING: {
      label: "airConditioning",
      key: "hasAirConditioning",
    },
    HAS_ELEVATOR: { label: "elevator", key: "hasElevator" },
  });

export const APPRAISER_FORM_PROPERTY_ISSUES: IAppraiserPropertyIssuesList =
  Object.freeze({
    HAS_ALUMINOSIS_OR_OTHER: {
      label: "aluminosisOrOther",
      key: "hasAluminosisOrOther",
    },
  });

export const APPRAISER_FORM_REFURBISHMENT_TYPES: IAppraiserRefurbishmentTypesList =
  Object.freeze({
    TOTAL: "total",
    KITCHEN: "kitchen",
    NONE: "notNeeded",
  });

export const APPRAISER_VALUATION_BANNER_TYPES: IAppraiserValuationBannerTypeList =
  Object.freeze({
    IBUYER: "ibuyer",
    BUY_TO_RENT: "buy_to_rent",
    GENERIC: "generic",
    SUCCESS: "success",
  });
