var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex pb-2.5",class:{
    'border-b border-edgeWater': _vm.bordered,
    'overflow-x-auto overflow-y-hidden': _vm.$mqm,
  }},[_c('ul',{staticClass:"flex"},_vm._l((_vm.tabs),function(tab){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(tab.available),expression:"tab.available"}],key:tab.label,staticClass:"pr-25 cursor-pointer",on:{"click":function($event){return _vm.changeTab(tab)}}},[_c('span',{staticClass:"\n          pb-2.5\n          md:pb-11\n          2xl:pb-3\n          whitespace-nowrap\n          text-base\n          2xl:text-lg\n        ",class:[
          tab.selected
            ? _vm.secondary
              ? 'text-midnight border-b border-midnight font-bold'
              : 'text-appMain border-b border-appMain font-bold'
            : _vm.secondary
            ? 'text-smaltBlue'
            : 'text-midnight' ]},[_vm._v(_vm._s(_vm.$t(tab.label)))])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }