import esLocal from "@/config/es.local.json";
import esPre from "@/config/es.pre.json";
import esProd from "@/config/es.prod.json";
import itLocal from "@/config/it.local.json";
import itPre from "@/config/it.pre.json";
import itProd from "@/config/it.prod.json";
import ptLocal from "@/config/pt.local.json";
import ptPre from "@/config/pt.pre.json";
import ptProd from "@/config/pt.prod.json";
import { Country, Environment, IConfig, IHiboConfig } from "@/models/Config";

const CONFIG: IHiboConfig[] = [
  { country: Country.ES, env: Environment.LOCAL, config: esLocal },
  { country: Country.ES, env: Environment.PRE, config: esPre },
  { country: Country.ES, env: Environment.PROD, config: esProd },
  { country: Country.IT, env: Environment.LOCAL, config: itLocal },
  { country: Country.IT, env: Environment.PRE, config: itPre },
  { country: Country.IT, env: Environment.PROD, config: itProd },
  { country: Country.PT, env: Environment.LOCAL, config: ptLocal },
  { country: Country.PT, env: Environment.PRE, config: ptPre },
  { country: Country.PT, env: Environment.PROD, config: ptProd },
];

class HiboConfig {
  private env: Environment;
  public readonly country: Country;
  public readonly userAgent: string;

  constructor() {
    this.env =
      (process.env.VUE_APP_ENVIRONMENT as Environment) || Environment.PROD;
    this.country = this.getCountryFromDomain();
    this.userAgent = this.getUserAgentFromNavigation();
  }

  get config(): IConfig {
    const hiboConfig = CONFIG.find(
      (config: IHiboConfig) =>
        config.country === this.country && config.env === this.env
    );
    return hiboConfig ? hiboConfig.config : esProd;
  }

  private getCountryFromDomain(): Country {
    const config = CONFIG.find(
      (hiboConfig: IHiboConfig) => hiboConfig.config.BASE_URL === location.host
    );
    return config ? config.country : Country.ES;
  }

  private getUserAgentFromNavigation(): string {
    return window.navigator.userAgent;
  }
}
export default new HiboConfig();
