
import { Component, Vue } from "vue-property-decorator";
@Component
export default class HiboFlap extends Vue {
  $refs!: {
    flapvisible: HTMLElement;
  };

  protected flapOpened = false;
  protected flapVisibleHeight = "0";
  protected flapMaxHeight = "95vh";

  mounted() {
    this.setflapVisibleHeigth();
  }

  protected setflapVisibleHeigth() {
    this.flapVisibleHeight = this.$refs.flapvisible.clientHeight + 14 + "px";
  }

  protected get hiddenTransformY() {
    return `translateY(calc(100% - ${this.flapVisibleHeight}))`;
  }
}
