
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import DOMService from "@/services/DOMService";
import { IAppraiserFormPropertyLocationInput } from "@/models/Appraiser";
import { IGooglePlace } from "@/models/Global";

declare const google: any;

@Component
export default class HiboGoogleAddressOrCadastralRefInput extends Vue {
  @Prop({ type: String, default: null })
  protected placeholder?: string;

  @Prop({ type: [String, Number], default: 1 })
  protected id?: string | number;

  @Prop({ type: String, default: null })
  protected icon?: string;

  @Prop({ type: String, default: null })
  protected errorText?: string;

  protected location = "";
  protected cadastralReference: string | null = null;
  protected place: IGooglePlace | null = null;
  private googleApiKey =
    process.env.VUE_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY_SECRET;
  private autocomplete: Record<string, any> = {};

  loadPlaces() {
    DOMService.loadJS(
      "googleapis",
      `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.googleApiKey}`,
      () => setTimeout(() => this.initAutocomplete())
    );
  }

  initAutocomplete() {
    const input = document.getElementById(
      "autocomplete" + this.id
    ) as HTMLInputElement;
    const options = {
      componentRestrictions: { country: "es" },
      fields: ["geometry", "name", "address_component", "formatted_address"],
      types: ["geocode"],
    };
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.autocomplete.addListener("place_changed", () => {
      this.handlePlaceChanged();
    });
  }

  handlePlaceChanged() {
    const place: IGooglePlace = this.autocomplete.getPlace();
    if (place) {
      this.place = place;
      this.location = place.formatted_address;
      this.cadastralReference = null;
    }
    this.change();
  }

  handleClear() {
    this.location = "";
    this.cadastralReference = null;
    this.place = null;
    this.change();
  }

  @Emit("click")
  click() {}

  @Emit("input")
  input() {
    const location: IAppraiserFormPropertyLocationInput = {
      cadastralReference: this.location,
      place: null,
    };
    return location;
  }

  @Emit("input")
  change() {
    const location: IAppraiserFormPropertyLocationInput = {
      cadastralReference: this.cadastralReference,
      place: this.place,
    };
    return location;
  }
}
