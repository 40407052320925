
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ISelectOption } from "@/models/Global";
import VueI18n from "vue-i18n";

@Component
export default class HiboMultiSelect extends Vue {
  @Prop({ type: Array, required: true })
  protected options!: ISelectOption[];

  @Prop({ type: String, default: null })
  protected icon?: string;

  @Prop({ type: [Array, String, Number], default: null })
  private value?: string[] | number[] | string | number;

  @Prop({ type: Boolean, default: false })
  protected relative?: boolean;

  @Prop({ type: Boolean, default: false })
  private translate?: boolean;

  @Prop({ type: Boolean, default: true })
  protected disabled?: boolean;

  @Prop({ type: String, default: null })
  private placeholder?: string;

  protected visible = false;
  protected optionsListStatus: boolean[] = [];
  protected optionsListStatusLastSave: boolean[] = [];
  protected activeOptions: Array<string | number> = [];

  protected get labelOrName(): string | undefined {
    const option = this.options?.find((op) => op.value === this.value);
    return option?.selectedLabel || option?.label || option?.name;
  }

  protected get selectedLabel(): string | VueI18n.TranslateResult {
    if (this.value && this.labelOrName)
      return this.$te(this.labelOrName)
        ? this.$t(this.labelOrName)
        : this.labelOrName;

    if (this.placeholder)
      return this.translate && this.$te(this.placeholder)
        ? this.$t(this.placeholder)
        : this.placeholder;

    return this.$t("pleaseSelectAnOption");
  }

  protected toggleList() {
    if (!this.disabled) this.visible = !this.visible;
    this.recoverLastStatus();
  }

  protected closeList() {
    if (this.visible) this.visible = false;
  }

  protected reset(save = false) {
    for (let i = 0; i < this.options.length; i++)
      Vue.set(this.optionsListStatus, i, false);
    if (save) this.save();
  }

  public forceReset() {
    this.optionsListStatusLastSave = [];
    this.reset();
  }

  protected recoverLastStatus() {
    if (this.optionsListStatusLastSave.length)
      this.optionsListStatusLastSave?.forEach((active: boolean, i) => {
        Vue.set(this.optionsListStatus, i, active);
      });
  }

  protected mounted() {
    this.reset();
  }

  @Emit("input")
  @Emit("change")
  save(): Array<string | number> {
    this.closeList();
    this.activeOptions = [];
    this.optionsListStatus?.forEach((active: boolean, i) => {
      if (active) this.activeOptions.push(this.options[i].value + "");
    });
    this.optionsListStatus?.forEach((active: boolean, i) => {
      Vue.set(this.optionsListStatusLastSave, i, active);
    });
    return this.activeOptions;
  }
}
