
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { inputOnlyNumbers } from "@/services/FormService";

@Component
export default class HiboInput extends Vue {
  @Prop({ type: String, default: null })
  protected icon?: string;

  @Prop({ type: String, default: "text" })
  protected type?: string;

  @Prop({ type: String, default: null })
  protected placeholder?: string;

  @Prop({ type: [String, Number], default: null })
  protected value?: [string, number];

  @Prop({ type: Number, default: null })
  protected min?: number;

  @Prop({ type: Number, default: null })
  protected step?: number;

  @Prop({ type: String, default: "default-input" })
  protected label?: string;

  @Prop({ type: Boolean, default: false })
  protected autofocus?: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled?: boolean;

  @Prop({ type: String, default: "off" })
  protected autocomplete?: string;

  @Prop({ type: String, default: null })
  protected name?: string;

  @Prop({ type: String, default: null })
  protected id?: string;

  @Prop({ type: String, default: null })
  protected errorText?: string;

  @Prop({ type: Boolean, default: false })
  protected integer?: boolean;

  protected handleKeydown(event: KeyboardEvent) {
    if (this.integer) this.validateInteger(event);
  }

  protected validateInteger(event: KeyboardEvent) {
    if (!inputOnlyNumbers(event)) event.preventDefault();
  }

  @Emit("input")
  handleChange(e: any) {
    return e.target.value;
  }
}
