var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"cursor-pointer lg:whitespace-nowrap font-bold",class:{
    'inline-flex items-center justify-center font-medium': _vm.loading,
    'bg-appMain hover:bg-appMainAccent text-white':
      !_vm.secondary && !_vm.tertiary && !_vm.disabled,
    'py-3 px-4 2xl:py-15 2xl:px-25': !_vm.small && !_vm.circle,
    'text-sm py-10 px-3': _vm.small,
    'border border-appMain text-appMain hover:border-appMainAccent hover:text-appMainAccent':
      _vm.secondary && !_vm.disabled,
    'border border-parisWhite hover:bg-snowDrift': _vm.tertiary,
    'bg-snowDrift cursor-not-allowed text-towerGrey': _vm.disabled,
    'rounded-full p-2': _vm.circle,
    'rounded-lg': !_vm.circle,
    'active:text-appMain': _vm.circle && _vm.tertiary,
  },attrs:{"type":_vm.type},on:{"click":function($event){return _vm.handleClick()}}},[(_vm.loading && !_vm.disabled)?_c('i',{staticClass:"rounded-full border-2 w-8 h-8 animate-spin",class:{
      'border-white': !_vm.secondary,
      'border-midnight': _vm.secondary,
      absolute: _vm.loading,
    },staticStyle:{"border-left":"solid transparent"}}):_vm._e(),_c('div',{class:{ invisible: _vm.loading }},[_vm._t("default",function(){return [_c('div',{staticClass:"flex items-center justify-center font-medium"},[(_vm.icon && !_vm.loading)?_c('HiboSvg',{class:{ 'mr-10 2xl:mr-5': !_vm.circle && _vm.text },attrs:{"svg":_vm.icon,"svg-class":_vm.circle && _vm.small ? 'w-3 h-3' : 'w-4 h-4 2xl:h-20 2xl:w-20'}}):_vm._e(),_c('span',{staticClass:"font-bold",class:{
            invisible: _vm.loading,
            'text-base 2xl:text-lg': !_vm.small,
            'text-sm 2xl:text-base': _vm.small,
          }},[_vm._v(_vm._s(_vm.text))])],1)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }