
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ISelectOption } from "@/models/Global";
import VueI18n from "vue-i18n";

@Component
export default class HiboDropdown extends Vue {
  @Prop({ type: Array, default: () => [] })
  protected options?: ISelectOption[];

  @Prop({ type: String, default: null })
  protected icon?: string;

  @Prop({ type: [String, Number], default: null })
  private value?: string | number;

  @Prop({ type: Boolean, default: false })
  private translate?: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled?: boolean;

  @Prop({ type: String, default: null })
  private placeholder?: string;

  @Prop({ type: Boolean, default: false })
  private withDataTracking?: boolean;

  protected visible = false;

  protected get selectedLabel(): string | VueI18n.TranslateResult {
    if (this.placeholder)
      return this.translate && this.$te(this.placeholder)
        ? this.$t(this.placeholder)
        : this.placeholder;

    return this.$t("pleaseSelectAnOption");
  }

  protected open() {
    if (!this.disabled) this.visible = !this.visible;
  }

  protected close() {
    this.visible = false;
  }

  @Emit("click")
  clicked(option: ISelectOption) {
    return option;
  }

  handleClick(option: ISelectOption) {
    if (!this.disabled) this.clicked(option);
  }
}
