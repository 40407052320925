import Vue from "vue";
import Vuex from "vuex";
import { IDashboardState, IActionContext } from "@/models/Store";
import { IResumePlotsResponse } from "@/models/Plots";
import api from "@/api";
import Money from "@/models/Money";

Vue.use(Vuex);

const state: IDashboardState = {
  resumePlots: [
    {
      name: "active_operations",
      titleKey: "activeOperations",
      labelKey: "",
      extraLabelKey: "",
      labelValue: null,
      total: null,
    },
    {
      name: "total_winnings",
      titleKey: "totalWinnings",
      labelKey: "invoicedOperations",
      labelValue: null,
      total: null,
    },
  ],
  dashboardLoading: false,
  isAlbroksa: false,
};

export default {
  namespaced: true,
  state,
  mutations: {
    START_DASHBOARD_LOADING: (state: IDashboardState) => {
      state.dashboardLoading = true;
    },
    FINISH_DASHBOARD_LOADING: (state: IDashboardState) => {
      state.dashboardLoading = false;
    },
    SET_RESUME_PLOTS_DATA: (
      state: IDashboardState,
      data: IResumePlotsResponse | null
    ) => {
      if (data) {
        state.resumePlots[1].labelValue = data.totalBillingOperationsQty;
        state.resumePlots[0].total = data.potentialBillingOperationsQty;
        state.resumePlots[1].total =
          new Money(data.totalBillingMinUnit / 100).formatNoCurrency() + "€";

        if (!state.isAlbroksa) {
          state.resumePlots[0].labelValue =
            new Money(data.potentialBillingMinUnit / 100).formatNoCurrency() +
            "€";

          state.resumePlots[0].labelKey = "potentialBillingOf";
          state.resumePlots[0].extraLabelKey =
            state.resumePlots[1].labelValue > 0
              ? "dependingAgencyOperations"
              : (state.resumePlots[0].extraLabelKey =
                  "dependingHiboOperations");
        }
      } else state.resumePlots = [];
    },
    SET_IS_ALBROKSA: (state: IDashboardState, isAlbroksa: boolean) => {
      state.isAlbroksa = isAlbroksa;
    },
  },
  actions: {
    setResumePlots: async ({ commit }: IActionContext, business: string[]) => {
      try {
        commit("START_DASHBOARD_LOADING");
        const result: IResumePlotsResponse | null = business.includes(
          "mortgages"
        )
          ? await api.dashboard().getResumePlots()
          : null;

        commit("SET_RESUME_PLOTS_DATA", result);
      } catch (err) {
        console.error(err);
        return false;
      } finally {
        commit("FINISH_DASHBOARD_LOADING");
      }
    },

    setIsAlbroksa({ commit }: IActionContext, isAlbroksa: boolean) {
      commit("SET_IS_ALBROKSA", isAlbroksa);
    },
  },
  getters: {
    plotsLoading: (state: IDashboardState) => state.dashboardLoading,
    getResumePlots: (state: IDashboardState) => state.resumePlots,
    getIsAlbroksa: (state: IDashboardState) => state.isAlbroksa,
  },
};
