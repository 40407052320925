import HiboRequest from "./request";
import {
  IUser,
  IUserLoginCredentials,
  IUserForgottenPassRequestCredentials,
  IUserForgottenPassResetCredentials,
  IUserSignUpCredentials,
  IUserDataToUpdate,
} from "@/models/User";
import { IFranchise, ILoginData } from "@/models/Auth";

const PRODUCT_CODE = "hibo_agency";

export default class AuthApi {
  private baseUrl = "/auth/v1/housfy-account";
  private baseUrlHibo = "/hibo/v1";
  private request: HiboRequest;

  constructor(request: HiboRequest) {
    this.request = request;
  }

  login(data: IUserLoginCredentials): Promise<ILoginData> {
    const url = `${this.baseUrl}/login`;
    const reqData = { ...data, housfyProductCode: PRODUCT_CODE };
    return this.request.call<ILoginData>({
      method: "POST",
      url,
      data: reqData,
    });
  }

  autologin(token: string): Promise<ILoginData> {
    const url = `${this.baseUrl}/autologin`;
    const reqData = { token: token };
    return this.request.call<ILoginData>({
      method: "POST",
      url,
      data: reqData,
    });
  }

  logout(): Promise<void> {
    const url = `${this.baseUrl}/logout`;
    return this.request.call<void>({ method: "GET", url });
  }

  setUpPasswordAccount(data: IUserSignUpCredentials): Promise<ILoginData> {
    const url = `${this.baseUrl}/forgot-password/reset-password`;
    const reqData = { ...data, housfyProductCode: PRODUCT_CODE };
    return this.request.call<ILoginData>({
      method: "POST",
      url,
      data: reqData,
    });
  }

  forgotPassword(data: IUserForgottenPassRequestCredentials): Promise<boolean> {
    const url = `${this.baseUrl}/forgot-password/request-reset`;
    const reqData = { ...data, housfyProductCode: PRODUCT_CODE };
    return this.request.call<boolean>({ method: "POST", url, data: reqData });
  }

  resetPassword(data: IUserForgottenPassResetCredentials): Promise<void> {
    const url = `${this.baseUrl}/forgot-password/reset-password`;
    const reqData = { ...data, housfyProductCode: PRODUCT_CODE };
    return this.request.call<void>({ method: "POST", url, data: reqData });
  }

  updatePassword(data: Record<string, string | null>): Promise<void> {
    const url = `${this.baseUrl}/profile`;
    return this.request.call<void>({ method: "PATCH", url, data });
  }

  retrieveProfile(): Promise<IUser> {
    const url = `${this.baseUrlHibo}/housfy-account-hibo-agency`;
    return this.request.call<IUser>({ method: "GET", url });
  }

  updateUserProfile(data: IUserDataToUpdate): Promise<void> {
    const url = `${this.baseUrlHibo}/housfy-account-hibo-agency`;
    return this.request.call<void>({ method: "PATCH", url, data });
  }

  updateUserAvatar(data: any): Promise<void> {
    const fd = new FormData();
    fd.append("fileToUpload", data.file);
    const url = `${this.baseUrlHibo}/upload-user-photo`;
    return this.request.call<void>({ method: "POST", url, data: fd });
  }

  retrieveFranchises(): Promise<IFranchise[]> {
    const url = `${this.baseUrlHibo}/hibo-agency-franchise-list`;
    return this.request.call<IFranchise[]>({ method: "GET", url });
  }
}
