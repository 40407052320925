
import { Component, Vue } from "vue-property-decorator";
import AuthWrapper from "@/components/auth/AuthWrapper.vue";
import HiboConfig from "@/services/HiboConfig";

@Component({
  components: { AuthWrapper },
})
export default class Login extends Vue {
  protected routeName = this.$route?.name;
  protected homeUrl: string = HiboConfig.config.HIBO_BASE_URL;
}
