import Vue from "vue";
import Vuex from "vuex";
import { IInfoState, IActionContext } from "@/models/Store";
import { IUserDevice } from "@/models/Device";
import { IItpRequest, IItpResponse } from "@/models/User";
import { IApiResponse } from "@/models/Global";
import api from "@/api";
import MarketingService from "@/services/MarketingService";
import HiboConfig from "@/services/HiboConfig";

Vue.use(Vuex);

const state: IInfoState = {
  userDevice: undefined,
  country: "ES",
};

export default {
  namespaced: true,
  state,
  mutations: {
    SET_DEVICE: (state: IInfoState, device: IUserDevice) => {
      state.userDevice = device;
    },
    SET_COUNTRY: (state: IInfoState, country: string) => {
      state.country = country;
    },
    SET_USER_AGENT: (state: IInfoState, userAgent: string) => {
      state.userAgent = userAgent;
    },
  },
  actions: {
    init({ dispatch }: IActionContext) {
      const country = HiboConfig.country;
      const userAgent = HiboConfig.userAgent;
      dispatch("setUserAgent", userAgent);
      dispatch("setCountry", country);
    },
    setDevice: ({ commit }: IActionContext, userDevice: IUserDevice) => {
      document.device = userDevice;
      commit("SET_DEVICE", userDevice);
    },
    getLocationItp: async (
      _context: IActionContext,
      payload: IItpRequest
    ): Promise<IApiResponse> => {
      try {
        const result: IItpResponse[] = await api
          .simulator()
          .getLocationItp(payload);
        return { success: true, res: result };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      }
    },
    setCountry({ commit }: IActionContext, country: string) {
      commit("SET_COUNTRY", country);
      MarketingService.google().init(country);
    },
    setUserAgent({ commit }: IActionContext, userAgent: string) {
      document.userAgent = userAgent;
      commit("SET_USER_AGENT", userAgent);
    },
  },
  getters: {
    getDevice: (state: IInfoState) => state.userDevice,
    getUserAgent: (state: IInfoState) => state.userAgent,
  },
};
