import Vue from "vue";
import Vuex from "vuex";
import { ISearchState, IActionContext } from "@/models/Store";
import { ISearchResult, ITermSearch } from "@/models/Search";

import api from "@/api";
import { IApiResponse } from "@/models/Global";

Vue.use(Vuex);

const state: ISearchState = {
  searchResults: [],
  searchLoading: false,
};

export default {
  namespaced: true,
  state,
  mutations: {
    START_SEARCH_LOADING: (state: ISearchState) => {
      state.searchLoading = true;
    },
    FINISH_SEARCH_LOADING: (state: ISearchState) => {
      state.searchLoading = false;
    },
    SET_SEARCH_RESULTS(state: ISearchState, results: ISearchResult[]) {
      state.searchResults = results;
    },
  },
  actions: {
    searchOperations: async (
      { commit }: IActionContext,
      payload: ITermSearch
    ): Promise<IApiResponse> => {
      try {
        commit("START_SEARCH_LOADING");

        const result: ISearchResult[] = await api.search().searchLeads(payload);

        commit("SET_SEARCH_RESULTS", result);
        return { success: true, res: result };
      } catch (err) {
        console.error(err);
        return { success: true, res: err };
      } finally {
        commit("FINISH_SEARCH_LOADING");
      }
    },
  },
  getters: {
    searchLoading: (state: ISearchState) => state.searchLoading,
    getResults: (state: ISearchState) => state.searchResults,
  },
};
