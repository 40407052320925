import HiboRequest from "./request";
import {
  ISuppliesDataList,
  INewSuppliesRequest,
  INewSuppliesResponse,
  ISuppliesCallParams,
} from "@/models/Supplies";
import { IDocument } from "@/models/Documents";
import { ISuppliesComment } from "@/models/Supplies";
import StitchService from "@/services/StitchService";

export default class SuppliesApi {
  private request: HiboRequest;
  private baseUrl = "/hibo/v1";
  constructor(request: HiboRequest) {
    this.request = request;
  }

  async createOperation(
    data: INewSuppliesRequest
  ): Promise<INewSuppliesResponse> {
    const url = `${this.baseUrl}/after-sales-task-supplies`;
    const response = await this.request.call<INewSuppliesResponse>({
      method: "POST",
      url,
      data,
    });
    if (response) StitchService.trackEvent("lead", `AN_${response.id}`);
    return response;
  }

  getOperations(params: ISuppliesCallParams): Promise<ISuppliesDataList> {
    const url = `${this.baseUrl}/after-sales-tasks`;
    return this.request.call<ISuppliesDataList>({ method: "GET", url, params });
  }

  getSupplyFiles(data: number): Promise<IDocument[]> {
    const url = `${this.baseUrl}/after-sales-task/${data}/documents`;
    return this.request.call<IDocument[]>({ method: "GET", url });
  }

  uploadDocUrl(supplyId: number, docId: number): string {
    return `${process.env.VUE_APP_HIBO_API_URL}/api${this.baseUrl}/after-sales-task/${supplyId}/documents/${docId}/files`;
  }

  getSupplyComments(afterSalesTaskId: number): Promise<ISuppliesComment[]> {
    const url = `${this.baseUrl}/after-sales-tasks/${afterSalesTaskId}/comments`;
    return this.request.call<ISuppliesComment[]>({ method: "GET", url });
  }
}
