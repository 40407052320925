
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

@Component
export default class HiboCheckbox extends Vue {
  @Prop({ type: Boolean, default: false })
  protected value!: boolean;

  @Prop({ type: String, required: true })
  protected text!: string;

  @Prop({ type: [String, Number], required: true })
  protected id!: string | number;

  @Prop({ type: Boolean, default: false })
  protected disabled?: boolean;

  @Prop({ type: String, default: null })
  protected errorText?: string;

  protected checked = false;

  mounted() {
    if (this.disabled) return;
    if (this.value) this.checked = this.value;
  }

  @Watch("value")
  handleValue() {
    if (this.disabled) return;
    this.checked = this.value;
  }

  @Emit("input")
  toggle() {
    if (this.disabled) return;
    this.checked = !this.checked;
    return this.checked;
  }
}
