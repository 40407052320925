
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProfilePictureWidget extends Vue {
  private colors: string[] = ["midnight", "appMain", "brightSun", "amber"];

  @Prop({ type: String, default: "H" })
  protected text?: string;

  @Prop({ type: String, default: null })
  protected image?: string;

  @Prop({ type: String, default: null })
  protected color?: string;

  @Prop({ type: Boolean, default: false })
  protected big?: boolean;

  protected get name(): string {
    return this.text || "H";
  }

  get randomColor(): string {
    const randomNum = this.name?.length
      ? this.name?.length % this.colors.length
      : 0;
    return this.colors[randomNum];
  }
}
