
import { Component, Vue, Prop } from "vue-property-decorator";
import ImageService from "@/services/ImageService";

@Component
export default class HiboImg extends Vue {
  @Prop({ type: String, required: true })
  protected src!: string;

  @Prop({ type: String, default: null })
  protected cdnCrop?: string;

  @Prop({ type: [String, Number], default: null })
  protected cdnWidth?: string | number;

  @Prop({ type: [String, Number], default: null })
  protected cdnHeight?: string | number;

  @Prop({ type: Boolean, default: false })
  protected background?: boolean;

  @Prop({ type: String, default: null })
  protected alt?: string;

  @Prop({ type: Boolean, default: false })
  protected noPlaceholder?: boolean;

  get image() {
    return {
      src: ImageService.cdnImage(this.src, {
        width: !isNaN(Number(this.cdnWidth))
          ? Number(this.cdnWidth)
          : undefined,
        height: !isNaN(Number(this.cdnHeight))
          ? Number(this.cdnHeight)
          : undefined,
        crop: this.cdnCrop,
      }),
      error: ImageService.cdnImage("assets/placeholder.svg"),
      loading: this.noPlaceholder
        ? null
        : ImageService.cdnImage(
            `assets/placeholder_${Math.floor(Math.random() * 3)}.jpg`
          ),
    };
  }
}
