
import { Component, Vue, Watch } from "vue-property-decorator";
import TheHeader from "@/components/theheader/TheHeader.vue";
import TheSidebarDesktop from "@/components/thesidebar/TheSidebarDesktop.vue";
import TheSidebarMobile from "@/components/thesidebar/TheSidebarMobile.vue";
import Auth from "@/views/Auth.vue";
import HiboLoader from "@/components/ui/HiboLoader.vue";
import { mapGetters } from "vuex";
import { Dictionary } from "vue-router/types/router";
import StitchService from "@/services/StitchService";

@Component({
  computed: {
    ...mapGetters("auth", {
      initialLoading: "initialLoading",
      authenticated: "authenticated",
      useDashboard: "useDashboard",
      getBusiness: "getBusiness",
    }),
  },
  components: {
    HiboLoader,
    Auth,
    TheHeader,
    TheSidebarDesktop,
    TheSidebarMobile,
  },
})
export default class ViewsWrapper extends Vue {
  protected initialLoading!: boolean;
  protected authenticated!: boolean;
  protected useDashboard!: boolean;
  protected getBusiness!: string[];

  protected showMainLoader = true;

  get mainView(): string {
    return this.useDashboard ? "dashboard" : this.getBusiness[0];
  }

  get hasAutologinToken(): boolean {
    return !!this.$route.query.at;
  }

  get isAuthPage(): boolean {
    return this.$route.name ? this.$route.name?.includes("auth") : false;
  }

  get is404Page(): boolean {
    return this.$route.name === "404";
  }

  protected goToRouteName(
    routeName: string,
    queryParams: undefined | Dictionary<string | (string | null)[]> = undefined
  ) {
    if (queryParams) {
      delete queryParams.at;
      delete queryParams.name;
    }
    this.$router.push(
      { name: routeName, query: queryParams },
      () => (this.showMainLoader = false)
    );
  }

  @Watch("authenticated")
  authentication(isAuthenticated: boolean) {
    const authenticatedWithDashboard =
      isAuthenticated &&
      (this.$route.name === "authLogin" ||
        (this.$route.query.name === this.mainView && this.useDashboard));

    const authenticatedWithoutDashboard =
      isAuthenticated &&
      (this.$route.name === "authLogin" ||
        (this.$route.query.name && !this.useDashboard));

    const notAuthenticatedAndNotPublicPage =
      !isAuthenticated && !this.$route.meta?.public;

    const authenticatedToBusiness =
      isAuthenticated &&
      this.getBusiness.includes(this.$route.query.name as string);

    if (authenticatedToBusiness)
      this.goToRouteName(this.$route.query.name as string, this.$route.query);
    else if (authenticatedWithDashboard) this.goToRouteName(this.mainView);
    else if (authenticatedWithoutDashboard)
      this.goToRouteName(this.getBusiness[0]);
    else if (notAuthenticatedAndNotPublicPage) this.goToRouteName("authLogin");
    else this.showMainLoader = false;
  }

  async beforeMount() {
    StitchService.init();
    await this.$store.dispatch("info/init");
    const querystring = window.location.search;
    const params = new URLSearchParams(querystring);
    const autolog = params.get("at")
      ? await this.$store.dispatch("auth/autologin", {
          token: params.get("at"),
        })
      : false;
    if (!autolog) {
      await this.$store.dispatch("auth/init");
      if (this.authenticated) {
        if (this.$route.name === "authLogin") this.goToRouteName(this.mainView);
      } else {
        if (!this.$route.meta?.public) this.goToRouteName("authLogin");
        else if (this.$route.name === "authLogin" || this.$route.meta?.public)
          this.showMainLoader = false;
      }
    }
    if (this.authenticated)
      await this.$store.dispatch("appraiser/retrieveAppraiserState");
  }

  mounted() {
    this.handleResize();
    this.addResizeListener();
  }

  addResizeListener(): void {
    window.addEventListener("resize", this.handleResize);
  }
  handleResize(): void {
    this.parseClientWidth();
  }
}
