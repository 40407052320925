export default class DOMService {
  static loadJS(id: string, file: string, onload: any, onerror?: any) {
    // Prevents double load
    if (!document || document.getElementById(id)) return onload();
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = id;
    script.defer = true;
    script.src = file;
    script.onload = onload;
    script.onerror = onerror;
    document.body.appendChild(script);
  }

  static getParam(p: string) {
    if (!window) return;
    const match = RegExp("[?&]" + p + "=([^&]*)").exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }
}
