
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ISelectOption } from "@/models/Global";
import StringService from "@/services/StringService";

@Component
export default class HiboSelectAutocomplete extends Vue {
  @Prop({ type: String, default: "text" })
  protected type?: string;

  @Prop({ type: Boolean, default: false })
  protected autofocus?: boolean;

  @Prop({ type: Array, required: true })
  protected options!: ISelectOption[];

  @Prop({ type: String, default: null })
  protected icon?: string;

  @Prop({ type: String, default: "" })
  protected inputClass?: string;

  @Prop({ type: [String, Object, Array, Number], default: null })
  protected value?: string;

  @Prop({ type: Boolean, default: false })
  protected selectFirst?: boolean;

  @Prop({ type: Boolean, default: false })
  protected translate?: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled?: boolean;

  @Prop({ type: String, default: "" })
  protected placeholder?: string;

  @Prop({ type: String, default: "" })
  protected listAlign?: string;

  @Prop({ type: Boolean, default: false })
  protected matchingStart?: boolean;

  @Prop({ type: Boolean, default: false })
  protected autocomplete?: boolean;

  @Prop({ type: Boolean, default: false })
  protected actionOnChange?: boolean;

  @Prop({ type: Boolean, default: true })
  protected hasResultsBox?: boolean;

  @Prop({ type: String, default: null })
  protected errorText?: string;

  protected timer = 0;
  protected selected: ISelectOption | null = null;
  protected visible = false;
  protected currValue = "";
  protected customizedOptions: ISelectOption[] = this.options;
  protected currentItem = 1;
  protected hoveredOptionIndex = -1;
  protected hoveredSelection = false;
  protected isAutocompleteEmpty: boolean | null = null;

  get selectAutocompleteInput(): HTMLElement {
    return this.$refs.selectAutocompleteInput as HTMLElement;
  }

  protected goToContainerOptions(event: KeyboardEvent) {
    const containerOptions: HTMLElement = this.$refs
      .containerOptions as HTMLElement;
    if (containerOptions) {
      this.currentItem = 0;
      containerOptions.focus();
      this.nextItem(event);
    } else this.openOptions();
  }

  protected nextItem(event: KeyboardEvent) {
    const optionsContainer: HTMLElement = this.$refs
      .containerOptions as HTMLElement;
    const availableOptions: HTMLElement[] = Array.from(
      optionsContainer?.querySelectorAll("li:not(.hidden)") || []
    );
    if (event) {
      if (
        !this.hoveredSelection &&
        (event.key === "ArrowDown" || event.key === "ArrowUp")
      ) {
        const hoveredOption = optionsContainer?.querySelector(
          "li:not(.hidden):hover"
        );

        if (hoveredOption) {
          this.hoveredSelection = true;
          if (hoveredOption?.parentElement?.children) {
            this.hoveredOptionIndex = [
              ...hoveredOption.parentElement.children,
            ].indexOf(hoveredOption);
          }

          this.currentItem = this.hoveredOptionIndex + 1;
        }
      }

      if (event.key === "ArrowUp") {
        if (this.currentItem > 1) this.currentItem--;
        else
          this.$nextTick(() => {
            this.selectAutocompleteInput.focus();
          });
      } else if (
        event.key === "ArrowDown" &&
        this.currentItem < availableOptions.length
      ) {
        this.currentItem++;
      }
    }
    if (availableOptions && availableOptions[this.currentItem - 1])
      availableOptions[this.currentItem - 1].focus();

    this.currValue = this.selected
      ? this.labelOrName(this.selected)
      : availableOptions[this.currentItem - 1]?.innerText || "";
  }
  protected resetOptions() {
    this.customizedOptions = this.customizedOptions.map(
      (arrayElem: ISelectOption) => {
        arrayElem.show = true;
        if (this.$te(arrayElem.label))
          arrayElem.translation = this.$t(arrayElem.label) as string;
        else arrayElem.translation = "";
        return arrayElem;
      }
    );
  }
  protected filterOptions() {
    // Filter all the options and hide those that aren't matching with current input string
    const currValue = StringService.slugify(this.currValue.toLowerCase());
    this.customizedOptions.forEach((op: ISelectOption) => {
      const label: string = StringService.slugify(op.label.toLowerCase());

      const translatedLabel: string = this.$te(label)
        ? StringService.slugify(this.$t(label).toString().toLowerCase())
        : label;

      const translatedCurrValue = StringService.slugify(
        currValue.toLowerCase()
      );

      op.show = this.matchingStart
        ? translatedLabel.startsWith(translatedCurrValue)
        : translatedLabel.includes(translatedCurrValue);
    });
  }

  protected labelOrName(op: ISelectOption): string {
    const label: string = op.label || op.name || "";
    if (label) {
      return this.translate && this.$te(label)
        ? (this.$t(label) as string)
        : label;
    } else return "";
  }

  protected isSelected(op: ISelectOption): boolean {
    if (!this.selected) return false;

    return op.value === this.selected.value;
  }

  protected close() {
    this.visible = false;
  }

  protected checkOptionExists(
    option: string | ISelectOption
  ): ISelectOption | null {
    let existingOption: string | ISelectOption | null = option;
    if (typeof existingOption === "string") {
      existingOption =
        this.options.find(
          (obj: ISelectOption) =>
            StringService.slugify(existingOption as string).toLowerCase() ===
            StringService.slugify(this.labelOrName(obj) as string).toLowerCase()
        ) || null;
    }
    return existingOption;
  }

  protected openOptions() {
    if (this.disabled) return;
    clearTimeout(this.timer);
    this.timer = window.setTimeout(
      () => this.$emit("input", this.currValue),
      500
    );

    this.hoveredOptionIndex = -1;
    // If a valid option is already selected show all options (If input is empty too) else filter options depending on input current value

    const optionExists = this.checkOptionExists(this.currValue);
    if (optionExists && this.isSelected(optionExists)) this.resetOptions();
    else this.filterOptions();
    this.visible = true;
    this.isAutocompleteEmpty = !!this.customizedOptions.filter((op) => op.show);
  }

  protected changeOption(option: ISelectOption) {
    if (this.actionOnChange) {
      this.currValue = "";
      this.close();
      this.$emit("change", option);
    } else {
      const optionExists: ISelectOption | null = this.checkOptionExists(option);
      if (optionExists) {
        if (optionExists !== this.selected) {
          this.selected = optionExists;
          this.currValue = this.labelOrName(optionExists);
          this.$emit("input", optionExists.value);
          this.$emit("change", optionExists);
        }
      }

      this.currValue = this.selected ? this.labelOrName(this.selected) : "";
      this.selectAutocompleteInput.focus();
      this.close();
    }
  }
  protected async resetHoveredSelection(index: number) {
    if (index !== this.hoveredOptionIndex) this.hoveredSelection = false;

    await this.$nextTick();

    const optionsContainer: HTMLElement = this.$refs
      .containerOptions as HTMLElement;

    const focusedOption: HTMLElement | null = optionsContainer?.querySelector(
      "li:not(.hidden):focus"
    );

    this.hoveredOptionIndex = index || -1;
    if (focusedOption) {
      focusedOption.blur();
      optionsContainer.focus();
    }
    this.currentItem = this.hoveredOptionIndex + 1;
  }

  mounted() {
    if (this.value) {
      this.selected =
        this.options.find((o: ISelectOption) => o.value === this.value) || null;
      if (this.selected) this.currValue = this.labelOrName(this.selected);
    }

    // Set the initial options configuration (adding in all cases "show": true)
    this.resetOptions();
  }

  @Watch("value", { immediate: true })
  selectValue(val: ISelectOption) {
    if (val === null) this.currValue = "";
    if (this.options.length) {
      this.selected =
        this.options.find(
          (o: ISelectOption) => JSON.stringify(o.value) === JSON.stringify(val)
        ) || null;

      if (this.selected && this.selectFirst) this.selected = this.options[0];
    }
  }

  @Watch("options", { immediate: true })
  selectOption(val: ISelectOption[]) {
    this.resetOptions();
    this.selected = val.find((o) => o.value === this.value) || null;
    this.customizedOptions = val;
    if (this.hasResultsBox) {
      this.options.map((op) => {
        op.show = true;
      });
    }
  }
}
