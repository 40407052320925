import * as Sentry from "@sentry/browser";
import GoogleAnalyticsService from "@/services/marketing-services/GoogleAnalyticsService";
import DOMService from "@/services/DOMService";

export default class GoogleService {
  public country: string | null;
  private analyticsService: GoogleAnalyticsService;

  constructor() {
    this.country = null;
    this.analyticsService = new GoogleAnalyticsService();
  }

  public analytics(): GoogleAnalyticsService {
    return this.analyticsService;
  }

  public async init(country: string | null) {
    try {
      if (this.country === country) return;
      if (this.country) {
        window.dataLayer = [];
      }
      await this.loadTagManager(
        country ? country.toUpperCase() : "ES",
        !(process.env.VUE_APP_TRACK_EVENTS === "true")
      );
      this.country = country;
      this.debugInit(country);
    } catch (err) {
      console.error("GoogleService init error");
      Sentry.captureException("GoogleService init error: " + err);
    }
  }

  private loadTagManager(country: string | null, debug = false) {
    try {
      return new Promise((resolve) => {
        if (window.dataLayer) resolve;

        const GTM = debug ? "GTM-KJZD2QQ" : "GTM-5QM2T2Z";
        DOMService.loadJS(
          `h-gtm-${country}`,
          "https://www.googletagmanager.com/gtm.js?id=" + GTM,
          () => {
            window.dataLayer = window.dataLayer || [];
            this.addToDataLayer({
              "gtm.start": new Date().getTime(),
              event: "gtm.js",
            });
            this.debugTagManagerLoaded(GTM);
            resolve;
          },
          () => {
            window.dataLayer = window.dataLayer || [];
            resolve;
          }
        );
      });
    } catch (err) {
      console.error("GoogleService loadTagManager error");
      Sentry.captureException("GoogleService loadTagManager error: " + err);
    }
  }

  private addToDataLayer(data: any) {
    if (!window?.dataLayer) return;
    window.dataLayer.push(data);
  }

  private debugInit(country: string | null) {
    try {
      if (process.env.VUE_APP_ENVIRONMENT === "production") return;
      console.log("[Google] Init " + country);
    } catch (err) {
      console.error("GoogleService debugInit error");
      Sentry.captureException("GoogleService debugInit error: " + err);
    }
  }

  private debugTagManagerLoaded(GTM: string) {
    try {
      if (process.env.VUE_APP_ENVIRONMENT === "production") return;
      console.log("[Google] TagManagerLoaded " + GTM);
    } catch (err) {
      console.error("GoogleService debugTagManagerLoaded error");
      Sentry.captureException(
        "GoogleService debugTagManagerLoaded error: " + err
      );
    }
  }
}
