
import { Component, Vue } from "vue-property-decorator";
import AuthPasswordInput from "@/components/auth/AuthPasswordInput.vue";
import { required, email } from "vuelidate/lib/validators";
import { Validations } from "vuelidate-property-decorators";
import { IUserLoginCredentials } from "@/models/User";
import { IApiResponse } from "@/models/Global";
import NotificationModal from "@/components/common/NotificationModal.vue";
import HiboConfig from "@/services/HiboConfig";
import { mapGetters } from "vuex";
import MarketingService from "@/services/MarketingService";
import StitchService from "@/services/StitchService";

@Component({
  components: { AuthPasswordInput, NotificationModal },
  computed: {
    ...mapGetters("auth", {
      authenticated: "authenticated",
    }),
  },
})
export default class LoginForm extends Vue {
  protected authenticated!: boolean;

  protected modalOpen = false;
  protected email = (this.$route?.query?.email as string) || "";
  protected password = "";
  protected showAllFormErrors = false;
  protected apiResponse: IApiResponse | null = null;
  protected homeUrl: string = HiboConfig.config.HIBO_BASE_URL;

  protected get modalErrorContentKey(): string {
    return this.apiResponse?.res?.errorData?.code === 1000
      ? "wrongUserDataGivenPleaseResetPassword"
      : "wrongUserDataGivenPleaseContactUsForMoreInfo";
  }

  protected goToPasswordRequest() {
    this.modalOpen = false;
    this.$router.push({
      name: "authResetpassword",
      query: { email: this.email },
    });
  }

  async logIn(): Promise<IApiResponse> {
    const payload: IUserLoginCredentials = {
      email: this.email,
      password: this.password,
    };
    return await this.$store.dispatch("auth/login", payload);
  }

  async submitForm() {
    MarketingService.analytics().trackEventGeneric(
      "authentication",
      "submitForm",
      "login"
    );

    if (this.$v.$invalid) {
      this.showAllFormErrors = true;
      return;
    }
    this.apiResponse = await this.logIn();
    if (this.apiResponse.success) {
      StitchService.trackEvent("login_successful");
    } else {
      StitchService.trackEvent("login_unsuccessful");
    }
    this.modalOpen = !this.authenticated;
  }

  @Validations()
  validations = {
    email: { required, email },
    password: { required },
  };
}
