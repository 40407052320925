
import { Component, Vue } from "vue-property-decorator";
import { INavElements } from "@/models/Global";
import { NAV_MENU_BUSINESS, NAV_MENU_COMMON, NAV_MENU_HOME } from "@/constants";
import { mapGetters } from "vuex";
import { TOOL_TABS } from "@/constants/tools";

@Component({
  computed: {
    ...mapGetters("auth", {
      business: "getBusiness",
      useDashboard: "useDashboard",
    }),
  },
})
export default class TheSidebarMobile extends Vue {
  protected business!: string[];
  protected profileMenu = this.$router
    ? this.$router.options?.routes?.find((route) => route.name === "profile")
        ?.children
    : [];
  protected useDashboard!: boolean;

  protected get navElements(): INavElements[] {
    return [
      ...(this.useDashboard ? NAV_MENU_HOME : []),
      ...NAV_MENU_BUSINESS.filter((item: INavElements) =>
        this.business.includes(item.name)
      ),
      ...NAV_MENU_COMMON,
      {
        name: "account",
        label: "account",
        icon: "icons/account",
        urlName:
          this.profileMenu && this.profileMenu[0] && this.profileMenu[0].name
            ? this.profileMenu[0].name
            : "",
      },
    ];
  }

  protected isElementSelected(urlName: string) {
    if (urlName === "tools") {
      return TOOL_TABS.some((tab) => tab.routeName === this.$route.name);
    }
    return urlName === this.$route.name;
  }
}
