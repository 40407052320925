import HiboRequest from "./request";
import AuthApi from "./auth";
import SimulatorApi from "./simulator";
import MortgagesApi from "./mortgages";
import SuppliesApi from "./supplies";
import DashboardApi from "./dashboard";
import SearchApi from "./search";
import AppraiserApi from "./appraiser";

class ApiClient {
  public request: HiboRequest;
  constructor() {
    this.request = new HiboRequest();
  }

  setToken(token: string) {
    this.request.setToken(token);
  }

  setUuid(uuid: string) {
    this.request.setUuid(uuid);
  }

  resetToken() {
    this.request.resetToken();
  }

  mortgages() {
    return new MortgagesApi(this.request);
  }

  supplies() {
    return new SuppliesApi(this.request);
  }

  auth() {
    return new AuthApi(this.request);
  }

  simulator() {
    return new SimulatorApi(this.request);
  }

  appraiser() {
    return new AppraiserApi(this.request);
  }

  dashboard() {
    return new DashboardApi(this.request);
  }
  search() {
    return new SearchApi(this.request);
  }
}

export default new ApiClient();
