import { ISelectOption, IStatusFilter, ITh } from "@/models/Global";
import { ISuppliesFiltersSortKeys, ISuppliesType } from "@/models/Supplies";

import {
  ISuppliesClient,
  ISuppliesConstantStatusLists,
} from "@/models/Supplies";
import { DEFAULT_PHONE } from ".";

export const OPERATION_STATUS_LIST: ISuppliesConstantStatusLists =
  Object.freeze({
    LEAD: "lead",
    IN_PROGRESS: "in_progress",
    MANAGED: "managed",
    CHARGED: "charged",
    CONTACTING: "contacting",
    LOST: "lost",
  });

export const SUPPLIES_STATUS: IStatusFilter[] = [
  {
    id: OPERATION_STATUS_LIST.LEAD,
    name: "leadNew",
    icon: "icons/roundedExcl",
    statusId: 1,
  },
  {
    id: OPERATION_STATUS_LIST.IN_PROGRESS,
    name: "inProcess",
    icon: "icons/roundArrows",
    statusId: 2,
  },
  {
    id: OPERATION_STATUS_LIST.MANAGED,
    name: "managed",
    icon: "icons/houseWithEye",
    statusId: 3,
  },
  {
    id: OPERATION_STATUS_LIST.CHARGED,
    name: "done",
    icon: "icons/houseWithCheck",
    statusId: 4,
  },
  {
    id: OPERATION_STATUS_LIST.CONTACTING,
    name: "callAgain",
    icon: "icons/roundedExcl",
    statusId: 5,
  },
  {
    id: OPERATION_STATUS_LIST.LOST,
    name: "lost",
    icon: "icons/cross",
    statusId: 6,
  },
];

export const DEFAULT_SUPPLIES_CLIENT: ISuppliesClient = {
  fullName: "",
  email: "",
  phone: DEFAULT_PHONE,
  supplyType: "",
  extraInfo: "",
  conditions: false,
  countryIsoCode: "ES",
};

export const FILTER_STATUS_OPTIONS: ISelectOption[] = [
  ...SUPPLIES_STATUS.map((status: IStatusFilter) => {
    return {
      label: status.name,
      name: status.name,
      value: status.statusId,
      show: true,
    };
  }),
];

export const SUPPLIES_TYPES_LIST: any = Object.freeze({
  LIGHTGAS: "change_light_gas",
});

export const SUPPLIES_TYPES: ISuppliesType[] = [
  { id: 7, code: SUPPLIES_TYPES_LIST.LIGHTGAS, labelKey: "lightGas" },
];

export const AFTER_SALES_TASK_SUPPLY_FORM_OPTIONS: ISelectOption[] = [
  {
    label: "electricity",
    value: "electricity",
  },
  {
    label: "gas",
    value: "gas",
  },
  {
    label: "electricityGas",
    value: "electricity_gas",
  },
];

export const SUPPLIES_FILTERS_SORT_KEYS: ISuppliesFiltersSortKeys = {
  LEAD_ID: {
    filter: "leadId",
    sort: "after_sales_task.after_sales_task_id",
  },
  CLIENT_NAME: {
    filter: "client",
    sort: "u.first_name",
  },
  CREATED_AT: {
    filter: "created_at",
    sort: "after_sales_task.created_at",
  },
  SUPPLY: {
    filter: "created_at",
    sort: "supply",
  },
  STATUS: {
    filter: "status",
    sort: "status",
  },
  FRANCHISE: {
    filter: "franchises",
    sort: "franchises",
  },
  DOCUMENT_STATUS: {
    filter: "document_status",
    sort: "document_status",
  },
};

export const LIST_TH: ITh[] = [
  {
    id: SUPPLIES_FILTERS_SORT_KEYS.LEAD_ID.sort,
    titleKey: "leadId",
    sortDirection: "desc",
    sortable: true,
  },
  {
    id: SUPPLIES_FILTERS_SORT_KEYS.CLIENT_NAME.sort,
    titleKey: "clientName",
    sortDirection: "desc",
    sortable: true,
  },
  {
    id: SUPPLIES_FILTERS_SORT_KEYS.SUPPLY.sort,
    titleKey: "service",
    sortDirection: "desc",
    sortable: false,
  },
  {
    id: SUPPLIES_FILTERS_SORT_KEYS.CREATED_AT.sort,
    titleKey: "creationDate",
    sortDirection: "desc",
    sortable: true,
  },
  {
    id: SUPPLIES_FILTERS_SORT_KEYS.STATUS.sort,
    titleKey: "status",
    sortDirection: "desc",
    sortable: true,
  },
  {
    id: SUPPLIES_FILTERS_SORT_KEYS.DOCUMENT_STATUS.sort,
    titleKey: "documentation",
    sortDirection: "desc",
    sortable: true,
  },
];
