interface CdnParams {
  width?: number;
  height?: number;
  crop?: string;
}

class ImageService {
  private urlFetch =
    "https://res.cloudinary.com/housfy/image/upload/f_auto,q_auto/";

  private getParams({ width, height, crop }: CdnParams = {}): string {
    const params = [];

    if (width) params.push(`w_${width}`);

    if (height) params.push(`h_${height}`);

    if (crop) params.push(`c_${crop}`);

    return params.join(",");
  }

  public cdnImage(image: string, cdnParams: CdnParams = {}): string {
    const params = this.getParams(cdnParams);
    return `${this.urlFetch}${params}/hibo-agency/${image}`;
  }
}
export default new ImageService();
