import Vue from "vue";
import VueI18n from "vue-i18n";
import es from "@/assets/locales/es.json";
import it from "@/assets/locales/it.json";
import pt from "@/assets/locales/pt.json";
import en from "@/assets/locales/en.json";
import HiboConfig from "@/services/HiboConfig";
import { Country } from "@/models/Config";

Vue.use(VueI18n);

const translations = {
  es,
  it,
  pt,
  en,
};

export default new VueI18n({
  locale: HiboConfig.country,
  fallbackLocale: Country.ES,
  messages: translations,
});
