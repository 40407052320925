import HiboRequest from "./request";
import { ISearchResult, ITermSearch } from "@/models/Search";
export default class SearchApi {
  private request: HiboRequest;
  private baseUrl = "/hibo/v1";
  constructor(request: HiboRequest) {
    this.request = request;
  }

  searchLeads(params: ITermSearch): Promise<ISearchResult[]> {
    const url = `${this.baseUrl}/leads/search`;
    return this.request.call<ISearchResult[]>({
      method: "GET",
      url,
      params,
    });
  }
}
