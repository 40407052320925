import Vue from "vue";
import Vuex from "vuex";
import api from "@/api";
import HiboConfig from "@/services/HiboConfig";
import { IActionContext, IAppraiserState } from "@/models/Store";
import { IApiResponse, IPagination, IFilterSettings } from "@/models/Global";
import {
  IAppraiserCadastralInfoParams,
  IAppraiserCadastralInfoResponse,
  IAppraiserData,
  IAppraiserDataList,
  IAppraiserDataListSettings,
  IAppraiserNewValuationRequest,
  IAppraiserStateResponse,
} from "@/models/Appraiser";

Vue.use(Vuex);

const state: IAppraiserState = {
  enabled: true,
  valuations: [],
  currentValuation: null,
  appraiserLoading: false,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    sortDirection: "desc",
    totalPages: 0,
    totalItems: 0,
    countryIsoCode: HiboConfig.country.toUpperCase(),
  },
};

export default {
  namespaced: true,
  state,
  mutations: {
    SET_VALUATIONS(state: IAppraiserState, valuations: IAppraiserData[]) {
      state.valuations = valuations;
    },
    SET_CURRENT_VALUATION(state: IAppraiserState, valuation: IAppraiserData) {
      state.currentValuation = valuation;
    },
    CLEAR_CURRENT_VALUATION(state: IAppraiserState) {
      state.currentValuation = null;
    },
    SET_PAGINATION(state: IAppraiserState, pagination: IPagination) {
      state.pagination = { ...state.pagination, ...pagination };
    },
    START_APPRAISER_LOADING: (state: IAppraiserState) => {
      state.appraiserLoading = true;
    },
    FINISH_APPRAISER_LOADING: (state: IAppraiserState) => {
      state.appraiserLoading = false;
    },
    ENABLE_APPRAISER(state: IAppraiserState) {
      state.enabled = true;
    },
    DISABLE_APPRAISER(state: IAppraiserState) {
      state.enabled = false;
    },
  },
  actions: {
    retrieveValuations: async (
      { commit }: IActionContext,
      payload: IAppraiserDataListSettings
    ) => {
      try {
        commit("START_APPRAISER_LOADING");

        if (payload?.pagination) commit("SET_PAGINATION", payload.pagination);

        const params: IPagination = {
          page: state.pagination.page,
          itemsPerPage: state.pagination.itemsPerPage,
          sortField: state.pagination.sortField,
          sortDirection: state.pagination.sortDirection,
          countryIsoCode: state.pagination.countryIsoCode,
        };

        // TODO: use this in case we need to add filters in the future
        if (payload && payload.filters)
          payload.filters?.forEach((filter: IFilterSettings) => {
            if (!filter.value) return;
          });

        const valuationsList: IAppraiserDataList = await api
          .appraiser()
          .getValuations(params);

        commit("SET_VALUATIONS", valuationsList.items);
        commit("SET_PAGINATION", {
          page: valuationsList.page,
          itemsPerPage: valuationsList.itemsPerPage,
          totalItems: valuationsList.totalItems,
          totalPages: valuationsList.totalPages,
        });
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      } finally {
        const currentValuation = state.valuations.find(
          (valuation) => valuation.id === state.currentValuation?.id
        );
        if (currentValuation) commit("SET_CURRENT_VALUATION", currentValuation);
        commit("FINISH_APPRAISER_LOADING");
      }
    },

    createValuation: async (
      { commit, dispatch }: IActionContext,
      payload: IAppraiserNewValuationRequest
    ): Promise<IApiResponse> => {
      try {
        commit("START_APPRAISER_LOADING");
        const newValuationId: number = await api
          .appraiser()
          .createValuation(payload);
        return { success: true, res: newValuationId };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      } finally {
        await dispatch("retrieveValuations");
        commit("FINISH_APPRAISER_LOADING");
      }
    },

    updateValuation: async (
      { commit, dispatch }: IActionContext,
      valuationId: number
    ) => {
      try {
        commit("START_APPRAISER_LOADING");
        await api.appraiser().updateValuation(valuationId);
      } catch (err) {
        console.error(err);
      } finally {
        await dispatch("retrieveValuations");
        commit("FINISH_APPRAISER_LOADING");
      }
    },

    deleteValuation: async (
      { commit, dispatch }: IActionContext,
      valuationId: number
    ): Promise<void> => {
      try {
        commit("START_APPRAISER_LOADING");
        await api.appraiser().deleteValuation(valuationId);
      } catch (err) {
        console.error(err);
      } finally {
        await dispatch("retrieveValuations");
        commit("FINISH_APPRAISER_LOADING");
      }
    },

    setCurrentValuation({ commit }: IActionContext, valuation: IAppraiserData) {
      commit("SET_CURRENT_VALUATION", valuation);
    },

    clearCurrentValuation({ commit }: IActionContext) {
      commit("CLEAR_CURRENT_VALUATION");
    },

    getCadastralInfo: async (
      { commit }: IActionContext,
      params: IAppraiserCadastralInfoParams
    ): Promise<IApiResponse> => {
      try {
        commit("START_APPRAISER_LOADING");
        const cadastralInfo: IAppraiserCadastralInfoResponse = await api
          .appraiser()
          .getCadastralInfo(params);
        return { success: true, res: cadastralInfo };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      } finally {
        commit("FINISH_APPRAISER_LOADING");
      }
    },

    createCrossSellLead: async (
      { commit, dispatch }: IActionContext,
      valuationId: number
    ) => {
      try {
        commit("START_APPRAISER_LOADING");
        await api.appraiser().createCrossSellLead(valuationId);
      } catch (err) {
        console.error(err);
      } finally {
        await dispatch("retrieveValuations");
        commit("FINISH_APPRAISER_LOADING");
      }
    },

    retrieveAppraiserState: async ({
      commit,
    }: IActionContext): Promise<IApiResponse> => {
      try {
        const appraiserState: IAppraiserStateResponse = await api
          .appraiser()
          .getAppraiserState();
        if (appraiserState.hiboValuationEnabled) commit("ENABLE_APPRAISER");
        else commit("DISABLE_APPRAISER");
        return { success: true };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      }
    },
  },
  getters: {
    appraiserLoading: (state: IAppraiserState) => state.appraiserLoading,
    getValuations: (state: IAppraiserState) => state.valuations,
    getCurrentValuation: (state: IAppraiserState) => state.currentValuation,
    getPagination: (state: IAppraiserState) => state.pagination,
    getEnabled: (state: IAppraiserState) => state.enabled,
  },
};
