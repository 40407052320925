
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

const API_ERRORS = [
  {
    code: 1000,
    message: "wrongUserDataGivenPleaseResetPassword",
  },
  {
    code: 1014,
    message: "resetPasswordLinkExpired",
  },
  {
    code: 2,
    message: "userDoesNotExist",
  },
];

@Component
export default class notificationModal extends Vue {
  @Prop({ type: Number, default: 0 })
  protected errorCode?: number;

  @Prop({ type: String, default: "" })
  protected email?: string;

  @Prop({ type: String, default: "" })
  protected headerKey?: string;

  @Prop({ type: Boolean, default: false })
  protected isError?: boolean;

  @Prop({ type: String, default: "" })
  protected customBtn?: string;

  protected get modalErrorContentKey(): string {
    const errorData = API_ERRORS.find((error) => {
      return error.code === this.errorCode;
    });

    return errorData?.message || "wrongUserDataGivenPleaseContactUsForMoreInfo";
  }

  protected get modalHeaderKey(): string {
    if (this.isError) return "errorHappens";
    else if (this.headerKey) return this.headerKey;
    else return "";
  }

  protected goToPasswordRequest() {
    this.$router.push({
      name: "authResetpassword",
      query: { email: this.email },
    });
  }

  @Emit("close")
  handleClose() {}

  @Emit("clickButton")
  handleCustomBtn() {}
}
