
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { MODAL_RESULTS } from "@/constants";
import { IModalResultList } from "@/models/Global";

@Component
export default class HiboModal extends Vue {
  @Prop({ type: String, default: null })
  protected result?: string;

  protected modalResults: IModalResultList = MODAL_RESULTS;

  @Emit("close") close() {}

  toggleFixBackground(fixed: boolean) {
    const doc = document.querySelector("html");
    if (doc) doc.style.overflow = fixed ? "hidden" : "auto";
    const theHeader = document.getElementById("theHeader");
    if (theHeader) theHeader.style.zIndex = fixed ? "0" : "200";
  }
  mounted() {
    this.toggleFixBackground(true);
  }
  beforeDestroy() {
    this.toggleFixBackground(false);
  }
}
