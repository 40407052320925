import Vue from "vue";
import Vuex from "vuex";
import { IMortgagesState, IActionContext } from "@/models/Store";
import {
  IMortgageData,
  INewMortgageRequest,
  INewMortgageData,
  IUploadedFile,
  IMortgageDataListSettings,
  IMortgagesDataList,
  IMortgagesCallParams,
  IMortgagesUnreadMessages,
} from "@/models/Mortgages";
import {
  ISimulatorFormData,
  IMortgageQuote,
  IEuribor,
} from "@/models/Simulator";
import { IDocument, IDocumentUploadPayload } from "@/models/Documents";
import api from "@/api";
import { IApiResponse, IPagination, IFilterSettings } from "@/models/Global";
import { MORTGAGES_FILTERS_SORT_KEYS } from "@/constants/mortgages";
import HiboConfig from "@/services/HiboConfig";

Vue.use(Vuex);

const state: IMortgagesState = {
  mortgages: [],
  mortgages_last_search_results: [],
  mortgagesDocuments: [],
  mortgagesLoading: false,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    sortField: MORTGAGES_FILTERS_SORT_KEYS.LEAD_ID.sort,
    sortDirection: "desc",
    totalPages: 0,
    totalItems: 0,
    countryIsoCode: HiboConfig.country.toUpperCase(),
  },
  unreadMessagesMortgages: [],
  unreadMessagesInterval: 0,
  extraInfoMortgages: "",
};

export default {
  namespaced: true,
  state,
  mutations: {
    SET_OPERATIONS(state: IMortgagesState, mortgages: IMortgageData[]) {
      state.mortgages = mortgages;
    },
    SET_UNREAD_MESSAGES(
      state: IMortgagesState,
      unreadMessagesMortgages: IMortgagesUnreadMessages[]
    ) {
      state.unreadMessagesMortgages = unreadMessagesMortgages;
    },
    SET_UNREAD_MESSAGES_INTERVAL(
      state: IMortgagesState,
      unreadMessagesInterval: number
    ) {
      state.unreadMessagesInterval = unreadMessagesInterval;
    },
    SET_EXTRA_INFO(state: IMortgagesState, extraInfoMortgages: string) {
      state.extraInfoMortgages = extraInfoMortgages;
    },
    SET_PAGINATION(state: IMortgagesState, pagination: IPagination) {
      state.pagination = { ...state.pagination, ...pagination };
    },
    START_MORTGAGES_LOADING: (state: IMortgagesState) => {
      state.mortgagesLoading = true;
    },
    FINISH_MORTGAGES_LOADING: (state: IMortgagesState) => {
      state.mortgagesLoading = false;
    },
    SET_MORTGAGES_SEARCH_RESULTS(
      state: IMortgagesState,
      mortgages: IMortgageData[]
    ) {
      state.mortgages_last_search_results = mortgages;
    },
  },
  actions: {
    retrieveOperations: async (
      { commit }: IActionContext,
      payload: IMortgageDataListSettings
    ) => {
      try {
        commit("START_MORTGAGES_LOADING");
        if (payload)
          commit("SET_PAGINATION", payload.pagination || state.pagination);

        const params: IMortgagesCallParams = {
          page: state.pagination.page,
          itemsPerPage: state.pagination.itemsPerPage,
          sortField: state.pagination.sortField,
          sortDirection: state.pagination.sortDirection,
          countryIsoCode: state.pagination.countryIsoCode,
        };

        if (payload && payload.filters)
          payload.filters?.forEach((filter: IFilterSettings) => {
            if (!filter.value) return;
            if (filter.name === MORTGAGES_FILTERS_SORT_KEYS.FRANCHISE.filter)
              params.franchises = [filter.value as number];
            if (filter.name === MORTGAGES_FILTERS_SORT_KEYS.STATUS.filter)
              params.status = filter.value as number;
            if (filter.name === MORTGAGES_FILTERS_SORT_KEYS.LEAD_ID.filter)
              params.leadId = filter.value as number;
          });

        const operationsList: IMortgagesDataList = await api
          .mortgages()
          .getMortgages(params);

        commit("SET_OPERATIONS", operationsList.items);
        commit("SET_PAGINATION", {
          page: operationsList.page,
          itemsPerPage: operationsList.itemsPerPage,
          totalItems: operationsList.totalItems,
          totalPages: operationsList.totalPages,
        });
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      } finally {
        commit("FINISH_MORTGAGES_LOADING");
      }
    },

    createMortgage: async (
      { commit, dispatch }: IActionContext,
      payload: INewMortgageRequest
    ): Promise<INewMortgageData | null> => {
      try {
        commit("START_MORTGAGES_LOADING");
        const { isNewMortgage, mortgageId } = await api
          .mortgages()
          .createMortgage(payload);

        let res = { isNewMortgage };

        if (isNewMortgage && mortgageId) {
          const mortgage: IMortgageData = await api
            .mortgages()
            .getMortgage(mortgageId.toString());
          res = { ...res, ...mortgage };
        }
        return res;
      } catch (err) {
        console.error(err);
        return null;
      } finally {
        await dispatch("retrieveOperations");
        commit("FINISH_MORTGAGES_LOADING");
      }
    },

    getMortgageFiles: (
      { commit }: IActionContext,
      mortgageId: number
    ): Promise<IDocument[]> => {
      try {
        commit("START_MORTGAGES_LOADING");
        return api.mortgages().getMortgageFiles(mortgageId);
      } catch (err) {
        console.error(err);
        return Promise.reject();
      } finally {
        commit("FINISH_MORTGAGES_LOADING");
      }
    },

    getUploadDocUrl: (
      { commit }: IActionContext,
      payload: IDocumentUploadPayload
    ): string => {
      try {
        commit("START_MORTGAGES_LOADING");
        return api
          .mortgages()
          .uploadDocUrl(payload.operationId, payload.documentId);
      } catch (err) {
        console.error(err);
        return "";
      } finally {
        commit("FINISH_MORTGAGES_LOADING");
      }
    },

    uploadFile: async (
      { commit }: IActionContext,
      payload: { uploadUrl: string; file: any }
    ): Promise<IApiResponse> => {
      try {
        commit("START_MORTGAGES_LOADING");
        const result: IUploadedFile = await api
          .mortgages()
          .uploadFile(payload.uploadUrl, payload.file);
        return { success: true, res: result };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      } finally {
        commit("FINISH_MORTGAGES_LOADING");
      }
    },

    removeDocument: async (
      { commit }: IActionContext,
      payload: { mortgageId: number; documentId: number; fileId: number }
    ): Promise<boolean> => {
      try {
        commit("START_MORTGAGES_LOADING");
        await api
          .mortgages()
          .removeDocument(
            payload.mortgageId,
            payload.documentId,
            payload.fileId
          );
        return true;
      } catch (err) {
        console.error(err);
        return false;
      } finally {
        commit("FINISH_MORTGAGES_LOADING");
      }
    },

    getMortgageQuota: async (
      { commit }: IActionContext,
      payload: ISimulatorFormData
    ): Promise<IApiResponse> => {
      try {
        commit("START_MORTGAGES_LOADING");
        const result: IMortgageQuote = await api
          .mortgages()
          .getMortgageQuota(payload);
        return { success: true, res: result };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      } finally {
        commit("FINISH_MORTGAGES_LOADING");
      }
    },

    clearUnreadMessagesInterval: async ({ commit }: IActionContext) => {
      clearInterval(state.unreadMessagesInterval);
      commit("SET_UNREAD_MESSAGES_INTERVAL", 0);
    },

    getUnreadMessagesMortgages: async ({ commit }: IActionContext) => {
      if (state.unreadMessagesInterval === 0) {
        try {
          const unreadMessagesMortgages = async () => {
            try {
              const response: IMortgagesUnreadMessages[] = await api
                .mortgages()
                .getUnreadMessagesMortgages();
              commit("SET_UNREAD_MESSAGES", response);
            } catch (e) {
              console.log(e);
            }
          };
          await unreadMessagesMortgages();
          const unreadMessagesInterval = setInterval(
            () => unreadMessagesMortgages(),
            90000
          );
          commit("SET_UNREAD_MESSAGES_INTERVAL", unreadMessagesInterval);
        } catch (e) {
          console.log(e);
        }
      }
    },

    updateUnreadMessagesMortgagesIds: async (
      { commit, dispatch }: IActionContext,
      payload: {
        mortgagesId: number[];
        currentMortgageId: number | null;
      }
    ) => {
      try {
        await api.mortgages().updateIsReadMessage(payload.mortgagesId);
        const unreadMessagesMortgages = state.unreadMessagesMortgages.filter(
          (op) => !payload.mortgagesId.includes(op.id)
        );
        if (
          payload.mortgagesId.length === 1 &&
          payload.mortgagesId[0] === payload.currentMortgageId
        ) {
          const currentMortgageData: IMortgageDataListSettings = {
            pagination: {},
            filters: [
              {
                name: "leadId",
                value: payload.currentMortgageId,
              },
            ],
          };
          await dispatch("retrieveOperations", currentMortgageData);
        }
        commit("SET_UNREAD_MESSAGES", unreadMessagesMortgages);
      } catch (err) {
        console.error(err);
      }
    },

    updateMortgageExtraInfo: async (
      { commit }: IActionContext,
      payload: {
        currentMortgageId: number;
        extraInfo: string;
      }
    ) => {
      try {
        await api
          .mortgages()
          .updateExtraInfo(payload.currentMortgageId, payload.extraInfo);
        commit("SET_EXTRA_INFO", payload.extraInfo);
      } catch (err) {
        console.error(err);
      }
    },

    getEuribor: async ({ commit }: IActionContext): Promise<IApiResponse> => {
      try {
        commit("START_MORTGAGES_LOADING");
        const result: IEuribor = await api.mortgages().getEuribor();
        return { success: true, res: result };
      } catch (err) {
        console.error(err);
        return { success: false, res: err };
      } finally {
        commit("FINISH_MORTGAGES_LOADING");
      }
    },
  },
  getters: {
    mortgagesLoading: (state: IMortgagesState) => state.mortgagesLoading,
    getMortgages: (state: IMortgagesState) => state.mortgages,
    getUnreadMessagesMortgages: (state: IMortgagesState) =>
      state.unreadMessagesMortgages,
    getExtraInfoMortgages: (state: IMortgagesState) => state.extraInfoMortgages,
    getMortgagesLastSearchResults: (state: IMortgagesState) => state.mortgages,
    getPagination: (state: IMortgagesState) => state.pagination,
  },
};
