var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex control"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"relative z-5 w-full",attrs:{"tabindex":"0"}},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currValue),expression:"currValue"},{name:"focus",rawName:"v-focus",value:(_vm.autofocus),expression:"autofocus"}],ref:"selectAutocompleteInput",staticClass:"\n        bg-white\n        rounded-lg\n        w-full\n        placeholder-current\n        text-base\n        px-4\n        py-3\n        2xl:text-lg 2xl:py-4 2xl:px-6\n        truncate\n      ",class:[
        {
          'has-icon': _vm.icon,
          'border-none bg-snowDrift cursor-not-enabled text-edgeWater':
            _vm.disabled,
          'border border-towerGrey hover:bg-snowDrift focus:border-midnight':
            !_vm.errorText && !_vm.disabled,
          'border border-errorEcstasy': _vm.errorText && !_vm.disabled,
          'text-midnight': _vm.currValue,
          'text-towerGrey': !_vm.currValue,
        },
        _vm.inputClass ],attrs:{"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"autofocus":_vm.autofocus,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.currValue)?_vm._i(_vm.currValue,_vm.value)>-1:(_vm.currValue)},on:{"click":function($event){return _vm.openOptions()},"input":function($event){return _vm.openOptions()},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.goToContainerOptions($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.goToContainerOptions($event)}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();return _vm.close()},"change":function($event){var $$a=_vm.currValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.currValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.currValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.currValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currValue),expression:"currValue"},{name:"focus",rawName:"v-focus",value:(_vm.autofocus),expression:"autofocus"}],ref:"selectAutocompleteInput",staticClass:"\n        bg-white\n        rounded-lg\n        w-full\n        placeholder-current\n        text-base\n        px-4\n        py-3\n        2xl:text-lg 2xl:py-4 2xl:px-6\n        truncate\n      ",class:[
        {
          'has-icon': _vm.icon,
          'border-none bg-snowDrift cursor-not-enabled text-edgeWater':
            _vm.disabled,
          'border border-towerGrey hover:bg-snowDrift focus:border-midnight':
            !_vm.errorText && !_vm.disabled,
          'border border-errorEcstasy': _vm.errorText && !_vm.disabled,
          'text-midnight': _vm.currValue,
          'text-towerGrey': !_vm.currValue,
        },
        _vm.inputClass ],attrs:{"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"autofocus":_vm.autofocus,"type":"radio"},domProps:{"value":_vm.value,"checked":_vm._q(_vm.currValue,_vm.value)},on:{"click":function($event){return _vm.openOptions()},"input":function($event){return _vm.openOptions()},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.goToContainerOptions($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.goToContainerOptions($event)}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();return _vm.close()},"change":function($event){_vm.currValue=_vm.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currValue),expression:"currValue"},{name:"focus",rawName:"v-focus",value:(_vm.autofocus),expression:"autofocus"}],ref:"selectAutocompleteInput",staticClass:"\n        bg-white\n        rounded-lg\n        w-full\n        placeholder-current\n        text-base\n        px-4\n        py-3\n        2xl:text-lg 2xl:py-4 2xl:px-6\n        truncate\n      ",class:[
        {
          'has-icon': _vm.icon,
          'border-none bg-snowDrift cursor-not-enabled text-edgeWater':
            _vm.disabled,
          'border border-towerGrey hover:bg-snowDrift focus:border-midnight':
            !_vm.errorText && !_vm.disabled,
          'border border-errorEcstasy': _vm.errorText && !_vm.disabled,
          'text-midnight': _vm.currValue,
          'text-towerGrey': !_vm.currValue,
        },
        _vm.inputClass ],attrs:{"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"autofocus":_vm.autofocus,"type":_vm.type},domProps:{"value":_vm.value,"value":(_vm.currValue)},on:{"click":function($event){return _vm.openOptions()},"input":[function($event){if($event.target.composing){ return; }_vm.currValue=$event.target.value},function($event){return _vm.openOptions()}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.goToContainerOptions($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.goToContainerOptions($event)}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();return _vm.close()}}}),(_vm.icon)?_c('HiboSvg',{staticClass:"absolute top-2/4 transform -translate-y-2/4 left-4 text-midnight",attrs:{"svg":_vm.icon,"svg-class":"w-4 2xl:w-25"}}):_vm._e(),(_vm.hasResultsBox && _vm.options.length)?_c('HiboSvg',{staticClass:"absolute transform right-15 top-1/2 transform -translate-y-1/2",class:{
        'rotate-90': _vm.visible,
        '-rotate-90': !_vm.visible,
        'text-towerGrey': _vm.disabled,
        'text-midnight cursor-pointer': !_vm.disabled,
      },attrs:{"svg":"icons/chevronLeft","svg-class":"h-10"},on:{"click":function($event){_vm.visible ? _vm.close() : _vm.openOptions()}}}):_vm._e()],1),(_vm.hasResultsBox && _vm.visible && _vm.options.filter(function (op) { return op.show; }).length)?_c('div',{ref:"containerOptions",staticClass:"\n      absolute\n      top-full\n      min-w-full\n      z-50\n      mt-2\n      bg-white\n      overflow-hidden\n      rounded-lg\n      border border-midnight\n    ",class:[this.listAlign === 'right' ? 'right-0' : 'left-0'],attrs:{"tabindex":"0"},on:{"keydown":function($event){$event.preventDefault();return _vm.nextItem($event)}}},[_c('ul',{staticClass:"top-0 w-full p-0 m-0 list-none shadow-lg max-h-200 overflow-auto",class:{
        hoveredSelection: _vm.hoveredSelection,
      }},_vm._l((_vm.customizedOptions),function(op,index){return _c('li',{key:'index_' + op.value,staticClass:"\n          py-5\n          px-15\n          cursor-pointer\n          hover:bg-snowDrift\n          text-sm\n          flex\n          justify-start\n          whitespace-nowrap\n        ",class:{
          'selected text-appMain bg-appMainLight': _vm.isSelected(op),
          'text-midnight hover:bg-snowDrift': !_vm.isSelected(op),
          disabled: op.disabled,
          hidden: !op.show,
        },attrs:{"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.changeOption(op)},"mousemove":function($event){return _vm.resetHoveredSelection(index)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.changeOption(op)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.stopPropagation();return _vm.changeOption(op)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();return _vm.close()}]}},[(op.icon)?_c('HiboSvg',{staticClass:"mr-5 text-appMain",attrs:{"svg":op.icon,"svg-class":"w-25"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.labelOrName(op)))])],1)}),0)]):_vm._e(),(_vm.errorText)?_c('footer',[_vm._v(_vm._s(_vm.errorText))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }