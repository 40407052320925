var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"rounded-full cursor-pointer p-5",class:{
    'inline-flex items-center justify-center font-medium': _vm.loading,
    'bg-appMain hover:bg-appMainAccent text-white': !_vm.secondary && !_vm.disabled,
    'border border-1 border-midnight text-midnight hover:bg-snowDrift':
      _vm.secondary && !_vm.disabled,
    'bg-snowDrift cursor-not-allowed text-towerGrey': _vm.disabled,
  },attrs:{"type":_vm.type},on:{"click":function($event){return _vm.handleClick()}}},[(_vm.loading && !_vm.disabled)?_c('i',{staticClass:"rounded-full border-2 w-8 h-8 animate-spin",class:{
      'border-white': !_vm.secondary,
      'border-midnight': _vm.secondary,
      absolute: _vm.loading,
    },staticStyle:{"border-left":"solid transparent"}}):_vm._e(),_c('div',{class:{ invisible: _vm.loading }},[_vm._t("default",function(){return [_c('div',{staticClass:"flex items-center justify-center font-medium"},[(_vm.icon && !_vm.loading)?_c('HiboSvg',{attrs:{"svg":_vm.icon,"svg-class":"h-4"}}):_vm._e()],1)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }