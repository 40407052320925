
import * as Sentry from "@sentry/browser";
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { IItpRequest, IItpResponse } from "@/models/User";
import DOMService from "@/services/DOMService";
import { IAddressComponents, IApiResponse } from "@/models/Global";
import StringService from "@/services/StringService";

declare const google: any;

@Component
export default class HiboGoogleAddressInput extends Vue {
  @Prop({ type: String, default: null })
  protected placeholder?: string;

  @Prop({ type: [String, Number], default: 1 })
  protected id?: string | number;

  @Prop({ type: String, default: null })
  protected icon?: string;

  protected location = "";
  private addressComponents = [];
  private locality = "";
  protected locationId = 0;
  private googleApiKey =
    process.env.VUE_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY_SECRET;
  private autocomplete: Record<string, any> = {};

  loadPlaces() {
    DOMService.loadJS(
      "googleapis",
      `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.googleApiKey}`,
      () => setTimeout(() => this.initAutocomplete())
    );
  }

  initAutocomplete() {
    const input = document.getElementById(
      "autocomplete" + this.id
    ) as HTMLInputElement;
    const options = {
      componentRestrictions: { country: "es" },
      fields: ["geometry", "name", "address_component"],
      types: ["geocode"],
    };
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();
      this.addressComponents = place.address_components;
      if (!place) {
        // User did not select a prediction
        this.location = "";
      } else {
        this.location = place.name;
        this.getLocationId();
      }
    });
  }

  async getLocationId() {
    const data: IItpRequest = {
      countryIsoCode: "ES",
      category: "state",
    };
    try {
      const response: IApiResponse = await this.$store.dispatch(
        "info/getLocationItp",
        data
      );
      this.addressComponents.forEach((comp: IAddressComponents) => {
        comp.types.forEach((type: string) => {
          if (type === "locality") {
            this.locality = comp.short_name;
          } else return;
        });
      });

      if (response.success) {
        const res: IItpResponse[] = response.res;
        const locationData =
          res.find(({ name }: { name: string }) => name === this.locality) ||
          null;
        this.locationId = locationData ? locationData.locationId : 3670;
        const placeSlugified = StringService.slugify(this.location);
        window.dispatchEvent(
          new CustomEvent("google_place_changed", { detail: placeSlugified })
        );
      } else this.locationId = 3670;
    } catch (err) {
      console.error(err);
      Sentry.captureException(
        "GoogleAddresInput/getLocationId: " + (err as any).message
      );
    }
    this.handleComplete();
  }

  @Emit("input")
  handleComplete() {
    return this.locationId;
  }
}
