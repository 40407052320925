
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("info", {
      $mq: "getDevice",
    }),
  },
})
export default class MqComponent extends Vue {
  public $mq!: string;

  public get $mqm(): boolean {
    return this.$mq === "mobile";
  }
  public get $mqt(): boolean {
    return this.$mq === "tablet";
  }
  public get $mqd(): boolean {
    return this.$mq === "desktop";
  }
  parseClientWidth() {
    const device =
      document.documentElement.clientWidth > 1024
        ? "desktop"
        : document.documentElement.clientWidth > 768
        ? "tablet"
        : "mobile";
    this.$store.dispatch("info/setDevice", device);
  }
}
