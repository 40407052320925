import { IGooglePlace, IPhone } from "@/models/Global";
import { IAddressComponents } from "@/models/Global";

const ES_PHONE_REGEX = /^[679]{1}[0-9]{8}$/;

export const formatPhone = (prefix: string, phone: string): string => {
  if (!phone) return "";
  switch (prefix) {
    case "+34":
      return phone.length === 9
        ? [
            phone.slice(0, 3),
            " ",
            phone.slice(3, 5),
            " ",
            phone.slice(5, 7),
            " ",
            phone.slice(7),
          ].join("")
        : phone;
    default:
      return phone;
  }
};
export const validatePhone = (prefix: string, phone: string): boolean => {
  if (!phone) return false;
  switch (prefix) {
    case "+34":
      return phone.length === 9 && ES_PHONE_REGEX.test(phone);
    default:
      return true;
  }
};
export const isValidPhone = (phone: IPhone): boolean => {
  if (!phone) return false;
  return phone.isValid === true;
};
export const isChecked = (value: boolean): boolean => {
  return value;
};

export const isValidPlace = (place: IGooglePlace | null) => {
  if (!place) return false;
  const streetName = place.address_components?.find(
    (component: IAddressComponents) => component.types[0] === "route"
  )?.long_name;
  const streetNumber = place.address_components?.find(
    (component: IAddressComponents) => component.types[0] === "street_number"
  )?.short_name;

  return (
    !!streetName &&
    !!streetNumber &&
    !!place.name &&
    !!place.geometry.location.lat &&
    !!place.geometry.location.lng
  );
};

export const inputOnlyNumbers = (event: KeyboardEvent): boolean => {
  const key = event.key;

  return (
    (!event.shiftKey &&
      !event.altKey &&
      !event.ctrlKey &&
      // numbers
      +key >= 0 &&
      +key <= 9) ||
    // Backspace and Tab and Enter
    key === "Tab" ||
    key === "Enter" ||
    // Home and End
    key === "Home" ||
    key === "End" ||
    // left and right arrows
    key === "ArrowLeft" ||
    key === "ArrowRight" ||
    // Del and Ins
    key === "Delete" ||
    key === "Backspace" ||
    key === "Insert"
  );
};
