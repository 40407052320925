
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ITab } from "@/models/Global";

@Component
export default class HiboTabs extends Vue {
  @Prop({ type: Array, required: true })
  protected tabs!: ITab[];

  @Prop({ type: Boolean, default: false })
  protected secondary?: boolean;

  @Prop({ type: Boolean, default: false })
  protected bordered?: boolean;

  @Emit("changeTab")
  changeTab(tab: ITab): string {
    this.tabs.map((option) => {
      option.selected = false;
      option.selected = option.name === tab.name;
    });
    return tab.name;
  }
}
