
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class HiboBadge extends Vue {
  @Prop({ type: String, default: null })
  protected state?: string;

  @Prop({ type: String, default: null })
  protected text?: string;

  @Prop({ type: String, default: "" })
  protected textClass?: string;

  protected get normalizedState() {
    switch (this.state) {
      case "incomplete":
      case "rejected":
      case "denied":
        return "denied";
      case "approved":
      case "signed":
      case "verified":
        return "verified";
      case "in_review":
        return this.state;
      default:
        return null;
    }
  }

  protected get normalizedIcon() {
    switch (this.normalizedState) {
      case "verified":
        return "icons/check";
      case "in_review":
        return "icons/pending";
      case "denied":
        return "icons/warn";
      default:
        return null;
    }
  }

  protected get normalizedText() {
    if (this.text) return this.$t(this.text);
    switch (this.normalizedState) {
      case "verified":
        return this.$t("validated");
      case "in_review":
        return this.$t("inReview");
      case "denied":
        return this.$t("rejected");
      default:
        return "";
    }
  }
}
