import { IMortgageDocumentTranslations } from "@/models/Mortgages";
import { IAfterSalesDocumentTranslations } from "@/models/Supplies";
import {
  IConstantBusiness,
  IConstantFormTypes,
  IConstantFormValidationsMessagesKeys,
  IModalResultList,
  INavElements,
  IOperationsFiltersSortKeys,
  IPhone,
} from "@/models/Global";

export const MORTGAGE_DOC_LOKALISE_KEYS: IMortgageDocumentTranslations[] = [
  { id: 1, title: "dni", description: "bothDniSides" },
  { id: 2, title: "nie", description: "uploadNiePicture" },
  { id: 3, title: "passport", description: "addFirstPagePassport" },
  { id: 5, title: "employmentContract", description: "addEmploymentContract" },
  {
    id: 6,
    title: "employmentHistory",
    description: "addEmploymentHistoryFile",
  },
  {
    id: 7,
    title: "anulaIVAdeclaration",
    description: "monthlyOrQuarterlyIvaDeclaration",
  },
  { id: 9, title: "incomeLastYear", description: "addLastYearIncomeFile" },
  {
    id: 13,
    title: "freelanceRegistration",
    description: "addFreelanceRegistration",
  },
  { id: 14, title: "rentalAgreement", description: "addRentalContract" },
  {
    id: 16,
    title: "reservation",
    description: "addReservation",
  },
  {
    id: 37,
    title: "depositContract",
    description: "addDepositContract",
  },
  { id: 42, title: "303model", description: "add4LastQuarterlyFiles" },
  { id: 43, title: "130model", description: "add4LastQuarterlyFiles" },
  { id: 50, title: "payslip", description: "add3LastPayrolls" },
  { id: 51, title: "incomeDraft", description: "addIncomeDraft" },
  {
    id: 52,
    title: "bankStatement",
    description: "Add3LastMonthBankStatement",
  },
  { id: 56, title: "workLifeReport", description: "addWorkLifeReport" },
  { id: 57, title: "irpf", description: "addIrpfFile" },
  { id: 59, title: "loanReceipt", description: "add3LastReceiptEachLoan" },
  {
    id: 60,
    title: "freelanceReceipt",
    description: "add3LastFreelanceReceipt",
  },
  { id: 61, title: "orderNote", description: "addOrderNote" },
  {
    id: 62,
    title: "contributionBasis",
    description: "addContributionBasis",
  },
  {
    id: 63,
    title: "designation",
    description: "addDesignation",
  },
  {
    id: 64,
    title: "withholdingCertificate",
    description: "addWithholdingCertificate",
  },
  { id: 65, title: "rentReceipt", description: "addRentReceipts" },
  { id: 66, title: "390model", description: "addAnualIvaDeclaration" },
  { id: 67, title: "190model", description: "addAnualIrpfDeclaration" },
  {
    id: 68,
    title: "111model",
    description: "addQuarterlyDeclarationOfEmployersRetentions",
  },
  {
    id: 69,
    title: "115model",
    description: "addIncomeThroughRentAnualDeclaration",
  },
  {
    id: 70,
    title: "200model",
    description: "addCompanyTaxesDeclaration",
  },
  {
    id: 71,
    title: "certificateOfIncorporation",
    description: "addCertificateOfIncorporation",
  },
  {
    id: 72,
    title: "profitAndLossBalance",
    description: "addProfitAnLossCompanyBalanceSheet",
  },
  {
    id: 73,
    title: "doubleGaranteeScripture",
    description: "addPropertyScriptureNewPurchaseGuarantee",
  },
  { id: 74, title: "doubleGuaranteeDni", description: "addDoubleGuaranteeDni" },
  {
    id: 75,
    title: "buyingNote",
    description: "addBuyingNote",
  },
  {
    id: 76,
    title: "doubleGuaranteeSimpleNote",
    description: "addBuyingNote",
  },
  { id: 77, title: "cif", description: "addCompanyCif" },
  {
    id: 78,
    title: "amortizationTable",
    description: "addAmortizationTable",
  },
  {
    id: 79,
    title: "pensionCertificate",
    description: "addPensionCertificate",
  },
  {
    id: 80,
    title: "annualResolution",
    description: "addAnnualResolution",
  },
  {
    id: 81,
    title: "pensionReceipt",
    description: "add3LastPensionReceipts",
  },
  { id: 82, title: "Others", description: "addPertinentDoc" },
  {
    id: 83,
    title: "347model",
    description: "addInfoDeclarationOfOperationsHigher3005NotDeclared",
  },
  {
    id: 84,
    title: "notarialCertificate",
    description: "addNotarialCertificate",
  },
  {
    id: 85,
    title: "balanceSheet",
    description: "addCompanyBalanceSheet",
  },
  { id: 86, title: "131model", description: "addIrpfSplittedPayment" },
  { id: 87, title: "propertiesOwned", description: "addPropertiesOwned" },
  {
    id: 88,
    title: "administrativeDocumentation",
    description: "addAdministrativeDocumentation",
  },
  {
    id: 89,
    title: "energyPerformanceCertificate",
    description: "addEnergyPerformanceCertificate",
  },
  {
    id: 90,
    title: "mortgageReceipt",
    description: "Add3MortgageReceiptsFromBank",
  },
  { id: 91, title: "divorceDecree", description: "addDivorceDecree" },
  {
    id: 92,
    title: "mortgageSubrogationScripture",
    description: "addMortgageSubrogationScripture",
  },
  {
    id: 93,
    title: "bankStatement6months",
    description: "addBankStatement6months",
  },
  {
    id: 94,
    title: "projectApproval",
    description: "addProjectApproval",
  },
  {
    id: 95,
    title: "refurbishmentBudget",
    description: "addRefurbishmentBudget",
  },
  {
    id: 96,
    title: "subrogationSaleDeed",
    description: "addSubrogationSaleDeed",
  },
  {
    id: 98,
    title: "lopdConsent",
    description: "addLopdConsent",
  },
];

export const AFTER_SALES_DOC_LOKALISE_KEYS: IAfterSalesDocumentTranslations[] =
  [
    {
      code: "DNI cara",
      title: "dniFront",
      description: "addFrontDni",
    },
    {
      code: "DNI reverso",
      title: "dniBack",
      description: "addBackDni",
    },
    {
      code: "Factura de la luz",
      title: "electricityBill",
      description: "addElectricityBill",
    },
    {
      code: "Factura de gas",
      title: "gasBill",
      description: "addGasBill",
    },
    {
      code: "Consentimiento LOPD",
      title: "lopd",
      description: "addLopd",
    },
    {
      code: "NIE cara",
      title: "nieFront",
      description: "addNieFront",
    },
    {
      code: "NIE reverso",
      title: "nieBack",
      description: "addNieBack",
    },
    {
      code: "CIF",
      title: "cif",
      description: "addCompanyCif",
    },
    {
      code: "Factura del agua",
      title: "waterBill",
      description: "addWaterBill",
    },
    {
      code: "Contrato de compraventa o alquiler",
      title: "buyAndSellOrRentContract",
      description: "addBuyAndSellOrRentContract",
    },
    {
      code: "Documento del banco con CC",
      title: "bankCcDocument",
      description: "addBankCcDocument",
    },
    {
      code: "Otros",
      title: "Others",
      description: "addOtherDocs",
    },
  ];

export const FORM_TYPE: IConstantFormTypes = Object.freeze({
  MORTGAGE_AGENCY: 11,
  MORTGAGE_AGENCY_SIMULATOR: 12,
});

export const DEFAULT_PHONE: IPhone = {
  phone: 0,
  national: 0,
  internationalPhone: "",
  isValid: false,
};

export const VALIDATIONS_MESSAGES_KEYS: IConstantFormValidationsMessagesKeys =
  Object.freeze({
    minLength: "fieldTooShort",
    maxLength: "fieldTooLong",
    isValidPhone: "invalidPhone",
    required: "requiredField",
  });

export const BUSINESS: IConstantBusiness = Object.freeze({
  mortgages: "mortgages",
  supplies: "supplies",
});

export const NAV_MENU_HOME: INavElements[] = [
  {
    name: "home",
    label: "home",
    icon: "icons/statisticsHouse",
    urlName: "dashboard",
  },
];

export const NAV_MENU_BUSINESS: INavElements[] = [
  {
    name: BUSINESS.mortgages,
    label: "mortgages",
    icon: "icons/pillarsHouse",
    urlName: "mortgages",
    typeId: 1,
  },
  {
    name: BUSINESS.supplies,
    label: "supplies",
    icon: "icons/fourSquares",
    urlName: "supplies",
    typeId: 4,
  },
];

export const NAV_MENU_COMMON: INavElements[] = [
  {
    name: "tools",
    label: "tools",
    icon: "icons/filter",
    urlName: "tools",
  },
];

export const FILTERS_SORT_KEYS: IOperationsFiltersSortKeys = {
  LEAD_ID: {
    filter: "leadId",
    sort: "lead_id",
  },
  CLIENT_NAME: {
    filter: "client",
    sort: "client",
  },
  CREATED_AT: {
    filter: "created_at",
    sort: "created_at",
  },
  STATUS: {
    filter: "status",
    sort: "status",
  },
  FRANCHISE: {
    filter: "franchise",
    sort: "franchise",
  },
};

export const ADAIX_MASTER_ID = 1822;
export const ALBROKSA_MASTER_ID = 4698;
export const IAD_MASTER_ID = 7745;
export const REALTY_PLUS_MASTER_ID = 8269;
export const OKIFY_HOUSFY_ACCOUNT_ID = 8809;

export const MASTERS_FRANCHISES: number[] = [
  ADAIX_MASTER_ID,
  ALBROKSA_MASTER_ID,
  IAD_MASTER_ID,
  REALTY_PLUS_MASTER_ID,
  OKIFY_HOUSFY_ACCOUNT_ID,
];

export const BRANDS = Object.freeze({
  HOUSFY: "Housfy",
  HIBO: "Hibo",
  HOUSFY_PRO: "Housfy PRO",
  SANTANDER: "Santander",
});

export const BRANDS_ID = Object.freeze({
  HOUSFY: 1,
  HIBO: 2,
  SANTANDER: 3,
});

export const BRAND_EMAIL_ADDRESS = Object.freeze({
  HOUSFY: "@housfy.com",
  HIBO: "@hiboteca.com",
});

export const MASTER_OF = Object.freeze({
  FRANCHISES: "franchise",
  SALESPERSON: "salesman",
});

export const MODAL_RESULTS: IModalResultList = Object.freeze({
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
});
