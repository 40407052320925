
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class HiboFloatingButton extends Vue {
  @Prop({ type: Boolean, default: false })
  protected secondary?: boolean;

  @Prop({ type: String, default: null })
  protected icon?: string;

  @Prop({ type: Boolean, default: false })
  protected loading?: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled?: boolean;

  @Prop({ type: String, default: "button" })
  protected type?: string;

  @Emit("click")
  clicked() {}

  handleClick() {
    if (!this.loading && !this.disabled) this.clicked();
  }
}
