
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { IMortgagesUnreadMessages } from "@/models/Mortgages";

@Component({
  computed: {
    ...mapGetters("mortgages", {
      unreadMessagesMortgages: "getUnreadMessagesMortgages",
    }),
  },
})
export default class NotificationWidget extends Vue {
  protected dropdownOpen = false;

  @Prop({ type: Boolean, default: false })
  protected border?: boolean;

  @Prop({ type: Boolean, default: false })
  protected dropdown?: boolean;

  async removeNotifications(mortgages: IMortgagesUnreadMessages[]) {
    this.dropdownOpen = false;
    const ids: number[] = [];
    mortgages.forEach((mortgage) => {
      ids.push(mortgage.id);
    });
    await this.$store.dispatch("mortgages/updateUnreadMessagesMortgagesIds", {
      mortgagesId: ids,
      currentMortgageId: this.currentMortgageId,
    });
    if (ids.length === 1 && ids[0] !== this.currentMortgageId) {
      await this.$router.push({
        name: "mortgages",
        query: {
          id: "" + ids[0],
          isNotification: "1",
        },
      });
    }
  }

  protected get currentMortgageId() {
    return +this.$route.query.id;
  }
}
