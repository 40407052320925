import HiboRequest from "./request";
import { IItpRequest, IItpResponse } from "@/models/User";

export default class SimulatorApi {
  private request: HiboRequest;
  constructor(request: HiboRequest) {
    this.request = request;
  }

  getLocationItp(data: IItpRequest): Promise<IItpResponse[]> {
    const url = `/public/v1/locations/itp?countryIsoCode=${data.countryIsoCode}&category=${data.category}`;
    return this.request.call<IItpResponse[]>({ method: "GET", url, data });
  }
}
