
import { Component, Vue } from "vue-property-decorator";
import LoginForm from "@/components/auth/LoginForm.vue";
import ResetPassForm from "@/components/auth/ResetPassForm.vue";
import SignUpForm from "@/components/auth/SignUpForm.vue";

@Component({
  components: { LoginForm, ResetPassForm, SignUpForm },
})
export default class AuthWrapper extends Vue {
  protected routeName = this.$route?.name;

  get token(): string {
    return this.$route && this.$route.query && this.$route.query.token
      ? (this.$route.query.token as string)
      : "";
  }

  get titlePart1Key(): string {
    switch (this.routeName) {
      case "authSignup":
        return "SignUp";
      case "authResetpassword":
        return this.token ? "writeAPassword" : "introduceYourEmail";
      case "authLogin":
      default:
        return "logIn";
    }
  }

  get titlePart2Key(): string {
    switch (this.routeName) {
      case "authSignup":
        return "toManageYourClients";
      case "authResetpassword":
        return this.token ? "toUpdateIt" : "toRecoverYourPassword";
      case "authLogin":
      default:
        return "toAccesYourAccount";
    }
  }
}
