import Storage from "@/plugins/storage";
import { KEYS } from "@/plugins/storage";
import { BRANDS, BRANDS_ID } from "@/constants";
import * as Sentry from "@sentry/browser";
import UtmService from "@/services/UtmService";

const TRACK_STITCH = true;

interface IPayload {
  user_id: number | undefined;
  user_uuid: string | undefined;
  admin_user_id: number | undefined;
  admin_user_uuid?: string;
  browser_uuid: string;
  domain: string;
  full_url: string;
  product_name: string;
  product_id: number;
  area: string;
  event_name: string;
  event_detail: string | undefined;
  event_created_at: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  gclid?: string;
}

interface IProduct {
  name: string;
  id: number;
}

export default class StitchService {
  static init() {
    if (!document) return;
    document.body.addEventListener<"click">("click", (event) => {
      try {
        const findElementWithTrackingFromTarget = (
          element: any
        ): HTMLElement | null => {
          if (element.dataset?.tracking) return element;
          return element.parentNode
            ? findElementWithTrackingFromTarget(element.parentNode)
            : null;
        };
        const element = findElementWithTrackingFromTarget(event.target);
        if (element?.dataset?.tracking)
          StitchService.trackEvent("click", element.dataset.tracking);
      } catch (err) {
        console.error("[Stitch] Stitch Click tracking error");
        Sentry.captureException(`[Stitch] Stitch Click tracking error ${err}`);
      }
    });
    window.addEventListener("google_place_changed", ((event: {
      target: any;
      detail: string;
    }) => {
      try {
        const eventName = "input_autocomplete_" + event.detail;
        StitchService.trackEvent("click", eventName);
      } catch (err) {
        console.error("[Stitch] Stitch Click tracking error");
        Sentry.captureException(`[Stitch] Stitch Click tracking error ${err}`);
      }
    }) as () => any);
  }

  static trackEvent(eventName: string, eventDetail: string | null = null) {
    try {
      if (
        eventName !== "page_view" &&
        (!this.cookiesAvailability() ||
          !this.localStorageAvailability() ||
          !this.sessionStorageAvailability())
      )
        return;

      const userId = this.cookiesAvailability()
        ? Storage.getItemCookies(KEYS.USER_ID)
        : undefined;
      const userUuid = this.cookiesAvailability()
        ? Storage.getItemCookies(KEYS.USER_UUID)
        : undefined;
      const adminUserId = this.sessionStorageAvailability()
        ? Storage.getItemSessionStorage(KEYS.ADMIN_USER_ID)
        : undefined;
      const adminUserUuid = this.sessionStorageAvailability()
        ? Storage.getItemSessionStorage(KEYS.ADMIN_USER_UUID)
        : undefined;
      const utms = this.cookiesAvailability()
        ? UtmService.getUtmsFromUrlOrCookie()
        : Object.keys(UtmService.extractUTMSfromURL()).length !== 0
        ? UtmService.extractUTMSfromURL()
        : {
            utmSource: "(direct)",
            utmMedium: "(none)",
            utmCampaign: "(not set)",
          };
      const payload = {
        user_id: userId ? Number(userId) : undefined,
        user_uuid: userId ? userUuid : undefined,
        admin_user_id: adminUserId ? Number(adminUserId) : undefined,
        admin_user_uuid: adminUserId ? adminUserUuid : undefined,
        browser_uuid: this.getAndSetBrowserUuid(),
        domain: location.host,
        full_url: location.href,
        product_name: this.getProduct(location.host).name,
        product_id: this.getProduct(location.host).id,
        area: this.getArea(location.href),
        event_name: eventName,
        event_detail: eventDetail || undefined,
        event_created_at: new Date().toISOString(),
        device_type: document.device,
        user_agent: document.userAgent,
        utm_source: utms?.utmSource || undefined,
        utm_medium: utms?.utmMedium || undefined,
        utm_campaign: utms?.utmCampaign || undefined,
        utm_term: utms?.utmTerm || undefined,
        utm_content: utms?.utmContent || undefined,
        gclid: utms?.googleClientId || undefined,
      };

      this.sendEvent(payload);
    } catch (err) {
      console.error(`[Stitch] Stitch ${eventName} error`);
      Sentry.captureException(`[Stitch] Stitch ${eventName} error ${err}`);
    }
  }

  static localStorageAvailability(): boolean {
    try {
      return !!window.localStorage;
    } catch {
      return false;
    }
  }

  static sessionStorageAvailability(): boolean {
    try {
      return !!window.sessionStorage;
    } catch {
      return false;
    }
  }

  static cookiesAvailability(): boolean {
    try {
      return !!navigator.cookieEnabled;
    } catch {
      return false;
    }
  }

  static getProduct(location: string): IProduct {
    if (location.includes("santander"))
      return { name: BRANDS.SANTANDER, id: BRANDS_ID.SANTANDER };
    if (location.includes("housfy"))
      return { name: BRANDS.HOUSFY, id: BRANDS_ID.HOUSFY };
    return { name: BRANDS.HIBO, id: BRANDS_ID.HIBO };
  }

  static getArea(url: string): string {
    if (url.includes("dashboard")) return "private_web";
    if (url.includes("admin")) return "admin";
    return "public_web";
  }

  static getAndSetBrowserUuid(): string {
    let id;
    if (this.cookiesAvailability())
      id = Storage.getItemCookies(KEYS.BROWSER_UUID);
    if (id) return id;
    if (this.localStorageAvailability())
      id = Storage.getItem(KEYS.BROWSER_UUID);
    if (id) {
      if (this.cookiesAvailability()) {
        Storage.setItemCookies(KEYS.BROWSER_UUID, id);
        Storage.removeItem(KEYS.BROWSER_UUID);
      }
      return id;
    }
    id = this.generateUuid();
    if (this.cookiesAvailability())
      Storage.setItemCookies(KEYS.BROWSER_UUID, id);
    return id;
  }

  static generateUuid = () => {
    let d = new Date().getTime();
    let d2 = 0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
    });
  };

  static debugStitchEvent(
    eventName: string,
    eventDetail: string | undefined = undefined
  ) {
    if (process.env.VUE_APP_ENVIRONMENT === "production") return;
    console.log(`[Stitch] Stitch ${eventName} ${eventDetail}`);
  }
  static sendEvent(payload: IPayload) {
    if (!TRACK_STITCH) return;
    if (process.env.ENVIRONMENT !== "production") {
      this.debugStitchEvent(payload.event_name, payload.event_detail);
    }
    try {
      const url = process.env.VUE_APP_STITCH_URL || "";
      fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.error(`[Stitch] Stitch petition error`);
      Sentry.captureException(
        `[Stitch] Stitch petition error ${payload.event_name} ${err}`
      );
    }
  }
}
