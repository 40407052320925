import GoogleService from "@/services/marketing-services/GoogleService";
import GoogleAnalyticsService from "@/services/marketing-services/GoogleAnalyticsService";

class MarketingService {
  private readonly googleService: GoogleService;

  constructor() {
    this.googleService = new GoogleService();
  }

  /** Services */
  public google(): GoogleService {
    return this.googleService;
  }

  public analytics(): GoogleAnalyticsService {
    return this.google().analytics();
  }
}

export default new MarketingService();
