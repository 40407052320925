
import { Component, Vue } from "vue-property-decorator";
import { IKam, IUser } from "@/models/User";
import ProfilePictureWidget from "@/components/widgets/ProfilePictureWidget.vue";
import { mapGetters } from "vuex";

@Component({
  components: { ProfilePictureWidget },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
})
export default class Kam extends Vue {
  protected user!: IUser;

  get kam(): IKam | null {
    return this.user.hiboKam || null;
  }

  get kamFormattedPhone(): string {
    const phone: string = this.kam?.phoneNumber?.internationalPhone || "";
    return phone.startsWith("+34")
      ? [
          phone.slice(0, 3),
          " ",
          phone.slice(3, 6),
          " ",
          phone.slice(6, 9),
          " ",
          phone.slice(9),
        ].join("")
      : phone;
  }

  get kamFormattedFullName(): string {
    const splitFullName: string[] = this.kam?.fullName?.split(" ") || [];
    return splitFullName
      ? splitFullName
          .map((name) => name[0].toUpperCase() + name.slice(1))
          .join(" ")
      : "";
  }
}
