export const Languages = ["es", "it", "pt", "en"];

export enum Country {
  ES = "es",
  IT = "it",
  PT = "pt",
}

export enum Environment {
  LOCAL = "local",
  PRE = "pre",
  PROD = "production",
}

export interface IConfig {
  BASE_URL: string;
  HIBO_BASE_URL: string;
  IS_HOTJAR_VISIBLE: boolean;
}

export interface ICountryConfig {
  local: IConfig;
  pre: IConfig;
  prod: IConfig;
}

export interface IHiboConfig {
  country: Country;
  env: Environment;
  config: IConfig;
}
