import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { v4 as uuidv4 } from "uuid";
import { version } from "../../package.json";
import Storage from "@/plugins/storage";
import { ICall } from "@/models/Global";

class HiboRequest {
  protected api: AxiosInstance;
  constructor() {
    this.api = axios.create({
      baseURL: process.env.VUE_APP_HIBO_API_URL + "/api",
      timeout: 10000,
      headers: {
        "x-env": process.env.NODE_ENV,
        "x-housfy-client-uuid": Storage.getItem(
          Storage.keys().USER_UUID,
          this.createUuid()
        ),
        "x-housfy-app-name": "hibo-agencies",
        "x-housfy-app-version": version,
      },
    });
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response?.status === 401 ||
          error.response?.data?.code === 1000
        ) {
          this.resetToken();
        }
        return Promise.reject(error);
      }
    );
  }
  createUuid(): string {
    try {
      return uuidv4();
    } catch (error) {
      // If uuid fails (i.e. When gets crypto.getRandomValues())
      console.error("Alternative unique universal identifier");
      let d = new Date().getTime(),
        d2 = (performance && performance.now && performance.now() * 1000) || 0;
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        let r = Math.random() * 16;
        if (d > 0) {
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
      });
    }
  }
  setToken(token: string) {
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
    Storage.setItem(Storage.keys().USER_TOKEN, token);
  }
  setUuid(uuid: string) {
    this.api.defaults.headers["x-housfy-client-uuid"] = uuid;
  }
  async resetToken() {
    delete this.api.defaults.headers.common.Authorization;
    Storage.removeItem(Storage.keys().USER_TOKEN);
    this.api.defaults.headers["x-housfy-client-uuid"] = null;
  }

  call<T>({
    method = "GET",
    url,
    data,
    params,
    timeout = 10000,
  }: ICall): Promise<T> {
    return new Promise((resolve, reject) => {
      const options: AxiosRequestConfig = {
        method,
        url,
        data,
        params,
        timeout,
      };
      this.api(options)
        .then((response) => resolve(response.data))
        .catch((error) => {
          let message = "error.default";
          if (error.response) {
            if (error.response.data) {
              if (error.response.data.message)
                message = error.response.data.message;
              else message = error.response.statusText;
            } else message = error.response.statusText;
          }
          reject({
            message: message,
            errorData: error.response?.data,
            response: error.response,
          });
        });
    });
  }
}
export default HiboRequest;
