import Vue from "vue";
import Vuex from "vuex";
import HiboConfig from "@/services/HiboConfig";
import AuthStoreModule from "./auth";
import InfoStoreModule from "./info";
import MortgagesStoreModule from "./mortgages";
import SuppliesStoreModule from "./supplies";
import DashboardStoreModule from "./dashboard";
import SearchStoreModule from "./search";
import AppraiserStoreModule from "./appraiser";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    country: HiboConfig.country,
  },
  mutations: {},
  actions: {},
  modules: {
    info: InfoStoreModule,
    auth: AuthStoreModule,
    mortgages: MortgagesStoreModule,
    supplies: SuppliesStoreModule,
    dashboard: DashboardStoreModule,
    search: SearchStoreModule,
    appraiser: AppraiserStoreModule,
  },
});
