var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"relative rounded-full cursor-pointer p-5",class:{
      'inline-flex items-center justify-center font-medium': _vm.loading,
      'bg-appMain hover:bg-appMainAccent text-white': !_vm.secondary && !_vm.disabled,
      'bg-appMainAccent': !_vm.secondary && !_vm.disabled && _vm.visible,
      'border border-1 border-midnight text-midnight hover:bg-snowDrift':
        _vm.secondary && !_vm.disabled,
      'bg-snowDrift cursor-not-allowed text-towerGrey': _vm.disabled,
    },attrs:{"type":"button"},on:{"click":function($event){!_vm.disabled ? _vm.open() : null}}},[(_vm.loading && !_vm.disabled)?_c('i',{staticClass:"rounded-full border-2 w-8 h-8 animate-spin",class:{
        'border-white': !_vm.secondary,
        'border-midnight': _vm.secondary,
        absolute: _vm.loading,
      },staticStyle:{"border-left":"solid transparent"}}):_vm._e(),[_vm._t("default",function(){return [_c('div',{staticClass:"flex items-center justify-center font-medium"},[(_vm.icon && !_vm.loading)?_c('HiboSvg',{attrs:{"svg":_vm.icon,"svg-class":"h-4"}}):_vm._e()],1)]})]],2),(_vm.visible)?_c('div',{staticClass:"\n      absolute\n      flex flex-col\n      z-50\n      w-64\n      right-0\n      bottom-0\n      mb-3\n      transform\n      -translate-y-1/2\n    "},[_c('ul',{staticClass:"\n        bg-white\n        top-0\n        w-full\n        mt-3\n        list-none\n        rounded-lg\n        max-h-200\n        custom-shadow\n        overflow-hidden\n      "},_vm._l((_vm.options),function(op,index){return _c('li',{key:op.label || op.name,staticClass:"\n          py-4\n          pl-4\n          whitespace-nowrap\n          cursor-pointer\n          text-base text-midnight\n          hover:bg-gray-50\n        ",class:{
          'cursor-not-allowed': op.disabled,
          'border-b border-edgeWater': index < _vm.options.length - 1,
        },attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.handleClick(op)}}},[_vm._v(" "+_vm._s((_vm.$te(op.label) ? _vm.$t(op.label) : op.label) || (_vm.$te(op.name) ? _vm.$t(op.name) : op.name))+" ")])}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }