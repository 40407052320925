var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"control"},[_c('div',{staticClass:"relative"},[_c('input',{staticClass:"text-base 2xl:text-lg bg-white rounded-lg w-full px-4 py-3",class:{
        'has-icon': _vm.icon,
        'bg-snowDrift cursor-not-enabled text-towerGrey': _vm.disabled,
        'border bg-white text-midnight hover:bg-snowDrift focus:border-midnight':
          !_vm.disabled,
        'border-errorEcstasy': _vm.errorText && !_vm.disabled,
        'border-towerGrey': !_vm.errorText && !_vm.disabled,
      },attrs:{"id":_vm.id,"autocomplete":_vm.autocomplete,"aria-label":_vm.label,"type":_vm.type,"placeholder":_vm.placeholder,"autofocus":_vm.autofocus,"name":_vm.name,"disabled":_vm.disabled,"min":_vm.min,"step":_vm.step},domProps:{"value":_vm.value},on:{"blur":function($event){return _vm.$emit('blur')},"keydown":_vm.handleKeydown,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"input":_vm.handleChange}}),(_vm.icon)?_c('HiboSvg',{staticClass:"\n        absolute\n        top-2/4\n        transform\n        -translate-y-2/4\n        left-4\n        text-edgeWater\n      ",attrs:{"svg":_vm.icon,"svg-class":"w-25"}}):_vm._e()],1),(_vm.errorText)?_c('footer',[_vm._v(_vm._s(_vm.errorText))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }