
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { NAV_MENU_BUSINESS } from "@/constants";
import { ISelectOption } from "@/models/Global";
import { ISearchResult } from "@/models/Search";
import ArrayService from "@/services/ArrayService";

@Component
export default class OperationsSearch extends Vue {
  @Prop({ type: Boolean, default: true })
  protected hasResultsBox?: boolean;

  protected loading = false;
  protected term = "";
  protected results: ISearchResult[] = [];
  protected selectOptions: ISelectOption[] = [];

  protected getBusinessName(type?: number): string {
    return (
      NAV_MENU_BUSINESS.find((business) => business.typeId === type)?.name || ""
    );
  }

  protected goToOperation(operation: ISelectOption) {
    this.selectOptions = [];
    this.results = [];
    if (this.$route.query?.id !== operation.value + "")
      this.$router.push({
        name: this.getBusinessName(operation.type),
        query: { id: operation.value + "" },
      });
  }

  @Emit("result")
  async search(term: string): Promise<ISearchResult[]> {
    if (!this.loading) {
      if (!term) {
        this.results = [];
        this.selectOptions = [];
      } else {
        this.loading = true;
        this.results = (
          await this.$store.dispatch("search/searchOperations", {
            term,
          })
        ).res;
        this.selectOptions = this.selectOptions.filter((option) => {
          this.results.find((result) => {
            option.value === result.id;
          });
        });

        this.results.forEach((result) => {
          if (
            !this.selectOptions.find((option) => {
              option.value === result.id;
            })
          )
            this.selectOptions.push({
              label: `[Lead ${result.id}] ${result.fullContactName}`,
              name: result.fullContactName.toString(),
              value: result.id,
              type: result.type,
              icon:
                NAV_MENU_BUSINESS.find(
                  (business) => business.typeId === result.type
                )?.icon || "",
            });
          ArrayService.sortBy(this.selectOptions, "name", "asc");
        });
        this.loading = false;
      }
    }

    return this.results;
  }
}
