
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ISelectOption } from "@/models/Global";
import VueI18n from "vue-i18n";

@Component
export default class HiboSelect extends Vue {
  @Prop({ type: Array, default: () => [] })
  protected options?: ISelectOption[];

  @Prop({ type: String, default: null })
  protected icon?: string;

  @Prop({ type: [String, Number], default: null })
  private value?: string | number | null;

  @Prop({ type: Boolean, default: false })
  protected relative?: boolean;

  @Prop({ type: Boolean, default: false })
  private translate?: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled?: boolean;

  @Prop({ type: String, default: null })
  private placeholder?: string;

  protected visible = false;

  isSelected(op: ISelectOption) {
    return op.value === this.value;
  }

  open() {
    if (!this.disabled) this.visible = !this.visible;
    document.addEventListener("keydown", this.handleKeyDown);
  }

  close() {
    this.visible = false;
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(event: KeyboardEvent) {
    const option = document.querySelector(
      `[data-keydown^="${event.key}"],
        [data-keydown^="${event.key.toUpperCase()}"]`
    ) as HTMLInputElement;
    if (option) {
      const parent = option.parentNode as HTMLElement;
      parent.scrollTop = option.offsetTop;
    }
  }

  changeOption(option: ISelectOption) {
    if (option.value !== this.value) this.hasChanged(option.value || null);
    this.close();
  }

  @Emit("input")
  @Emit("change")
  hasChanged(value: string | number | null) {
    return value;
  }

  get labelOrName(): string | undefined {
    const option = this.options?.find((op) => op.value === this.value);
    return option?.selectedLabel || option?.label || option?.name;
  }

  get selectedLabel(): string | VueI18n.TranslateResult {
    if (this.value && this.labelOrName)
      return this.$te(this.labelOrName)
        ? this.$t(this.labelOrName)
        : this.labelOrName;

    if (this.placeholder)
      return this.translate && this.$te(this.placeholder)
        ? this.$t(this.placeholder)
        : this.placeholder;

    return this.$t("pleaseSelectAnOption");
  }
}
