
import { Component, Vue } from "vue-property-decorator";
import AuthPasswordInput from "@/components/auth/AuthPasswordInput.vue";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { Validations } from "vuelidate-property-decorators";
import {
  IUserLoginCredentials,
  IUserForgottenPassRequestCredentials,
  IUserForgottenPassResetCredentials,
} from "@/models/User";
import { IApiResponse } from "@/models/Global";
import NotificationModal from "@/components/common/NotificationModal.vue";

@Component({
  components: { AuthPasswordInput, NotificationModal },
})
export default class ResetPassForm extends Vue {
  protected modalOpen = false;
  protected email = (this.$route?.query?.email as string) || "";
  protected password = "";
  protected apiResponse: IApiResponse | null = null;
  protected formSuccess = false;
  protected showAllFormErrors = false;
  protected prefilled = !!this.$route?.query?.email || false;

  protected get modalTitleKey(): string {
    return this.token ? "passwordResetCompleted" : "passwordRecovery";
  }

  protected get modalContent1Key(): string {
    return this.token
      ? "youHaveResetPassword"
      : "weHaveReceivedYourRequestToResetYourAccountPassword";
  }

  protected get modalContent2Key(): string {
    return this.token
      ? "clickOnContinueToLogIn"
      : "weSentEmailIndicatingStepsToResetPassword";
  }

  protected get token(): string {
    return this.$route && this.$route.query && this.$route.query.token
      ? (this.$route.query.token as string)
      : "";
  }

  protected async logIn() {
    const payload: IUserLoginCredentials = {
      email: this.email,
      password: this.password,
    };
    await this.$store.dispatch("auth/login", payload);
    this.$router.push({ name: "dashboard" });
  }

  protected async submitForm() {
    if (this.$v.$invalid) {
      this.showAllFormErrors = true;
      return;
    }

    if (this.token) {
      this.apiResponse = await this.resetPassword();
    } else this.apiResponse = await this.newPassRequest();
    this.formSuccess = this.apiResponse.success;
    this.modalOpen = true;
  }

  protected get errorCode(): number | null {
    return this.apiResponse?.res?.errorData?.code || null;
  }

  protected async newPassRequest(): Promise<IApiResponse> {
    const payload: IUserForgottenPassRequestCredentials = {
      email: this.email,
    };
    return await this.$store.dispatch("auth/requestResetPassword", payload);
  }

  protected async resetPassword(): Promise<IApiResponse> {
    const payload: IUserForgottenPassResetCredentials = {
      email: this.email,
      password: this.password,
      token: this.token,
    };

    return await this.$store.dispatch("auth/resetPassword", payload);
  }

  protected goToPasswordRequest() {
    this.modalOpen = false;
    this.$router.push({
      name: "authResetpassword",
      query: { email: this.email },
    });
  }

  mounted() {
    if (this.email) this.$v.email.$touch();
  }

  @Validations()
  validations = {
    email: { required, email },
    password: {
      required: requiredIf(() => this.token),
    },
  };
}
