import Vue from "vue";
import MqComponent from "./plugins/Mq.vue";
import HiboSvg from "@/components/ui/HiboSvg.vue";
import HiboInput from "@/components/ui/HiboInput.vue";
import HiboGoogleAddressInput from "@/components/ui/HiboGoogleAddressInput.vue";
import HiboGoogleAddressOrCadastralRefInput from "@/components/ui/HiboGoogleAddressOrCadastralRefInput.vue";
import HiboModal from "@/components/ui/HiboModal.vue";
import HiboButton from "@/components/ui/HiboButton.vue";
import HiboRadioButton from "@/components/ui/HiboRadioButton.vue";
import HiboCheckbox from "@/components/ui/HiboCheckbox.vue";
import HiboSelect from "@/components/ui/HiboSelect.vue";
import HiboMultiSelect from "@/components/ui/HiboMultiSelect.vue";
import HiboSelectAutocomplete from "@/components/ui/HiboSelectAutocomplete.vue";
import HiboDropdown from "@/components/ui/HiboDropdown.vue";
import HiboFloatingDropdown from "@/components/ui/HiboFloatingDropdown.vue";
import HiboTelInput from "@/components/ui/HiboTelInput.vue";
import HiboImg from "@/components/ui/HiboImg.vue";
import HiboBadge from "@/components/ui/HiboBadge.vue";
import HiboFloatingButton from "@/components/ui/HiboFloatingButton.vue";
import HiboFlap from "@/components/ui/HiboFlap.vue";
import HiboTabs from "@/components/ui/HiboTabs.vue";
import clickOutsideDirective from "@/directives/clickOutsideDirective";
import focusDirective from "@/directives/focusDirective";
import VueLazyload from "vue-lazyload";
import HiboGoogleMap from "@/components/ui/HiboGoogleMap.vue";

Vue.mixin(MqComponent);
Vue.component("HiboSvg", HiboSvg);
Vue.component("HiboInput", HiboInput);
Vue.component("HiboModal", HiboModal);
Vue.component("HiboButton", HiboButton);
Vue.component("HiboCheckbox", HiboCheckbox);
Vue.component("HiboImg", HiboImg);
Vue.component("HiboSelect", HiboSelect);
Vue.component("HiboSelectAutocomplete", HiboSelectAutocomplete);
Vue.component("HiboTelInput", HiboTelInput);
Vue.component("HiboBadge", HiboBadge);
Vue.component("HiboRadioButton", HiboRadioButton);
Vue.component("HiboFlap", HiboFlap);
Vue.component("HiboGoogleAddressInput", HiboGoogleAddressInput);
Vue.component("HiboMultiSelect", HiboMultiSelect);
Vue.component("HiboDropdown", HiboDropdown);
Vue.component("HiboFloatingDropdown", HiboFloatingDropdown);
Vue.component("HiboFloatingButton", HiboFloatingButton);
Vue.component("HiboTabs", HiboTabs);
Vue.component("HiboGoogleMap", HiboGoogleMap);
Vue.component(
  "HiboGoogleAddressOrCadastralRefInput",
  HiboGoogleAddressOrCadastralRefInput
);
Vue.directive("clickOutside", clickOutsideDirective);
Vue.directive("focus", focusDirective);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  throttleWait: 400,
});
