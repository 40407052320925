
import { Component, Prop, Vue } from "vue-property-decorator";
import ProfilePictureWidget from "@/components/widgets/ProfilePictureWidget.vue";
import { RouteConfig } from "vue-router";

@Component({
  components: {
    ProfilePictureWidget,
  },
})
export default class ProfileWidget extends Vue {
  protected dropdownOpen = false;

  protected get profileMenu(): RouteConfig[] {
    const menu: RouteConfig[] = [];

    if (this.$router)
      this.$router.options.routes?.forEach((route: RouteConfig) => {
        if (route.name === "profile")
          route.children?.forEach((r: RouteConfig) => menu.push(r));
      });

    return menu;
  }

  @Prop({ type: Boolean, default: false })
  protected onlyImage?: boolean;

  @Prop({ type: Boolean, default: false })
  protected onlyFirstName?: boolean;

  @Prop({ type: Boolean, default: false })
  protected border?: boolean;

  @Prop({ type: Boolean, default: false })
  protected dropdown?: boolean;

  @Prop({ type: String, default: null })
  protected name?: string;

  @Prop({ type: String, default: null })
  protected image?: string;

  @Prop({ type: String, default: null })
  protected description?: string;

  @Prop({ type: String, default: null })
  protected color?: string;

  protected formattedName() {
    return this.onlyFirstName ? this.name?.split(" ", 1)[0] : this.name;
  }

  logout() {
    this.$store.dispatch("auth/logout");
  }
}
