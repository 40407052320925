var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'flex items-center ': _vm.horizontal || _vm.tags,
    'flex-shrink-0': _vm.tags,
  }},_vm._l((_vm.items),function(item,index){return _c('div',{key:(index + "-item.label"),class:{
      'mr-15 mx-1b first:ml-0 last:mr-0 flex-1': _vm.horizontal,
      'mr-15 mx-1 first:ml-0 last:mr-0': _vm.tags,
      'mr-15 mr-1b last:mr-0': _vm.tagsSquare,
      'mb-4 last:mb-0': !_vm.horizontal && !_vm.tags && !_vm.tagsSquare,
    }},[_c('label',{staticClass:"block rounded-lg w-full",class:{
        'rounded-full p-1': _vm.tags && !_vm.withoutContainer,
        'p-2': !_vm.tags && !_vm.withoutContainer,
        'border border-appMain': _vm.value === item.value && !_vm.withoutContainer,
        'bg-appMainUltraLight hover:bg-appMainLight':
          _vm.value === item.value && !_vm.withoutContainer,
        'border border-towerGrey hover:bg-snowDrift':
          _vm.value !== item.value && !_vm.withoutContainer,
        'my-15 my-5 py-15 px-20 cursor-pointer min-w-150': !_vm.withoutContainer,
      }},[_c('div',{staticClass:"flex items-center cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"appearance-none",style:(_vm.inputColors),attrs:{"type":"radio","data-tracking":_vm.dataTracking},domProps:{"value":item.value,"checked":_vm._q(_vm.localValue,item.value)},on:{"change":function($event){_vm.localValue=item.value}}}),(!_vm.iconless)?_c('div',{staticClass:"h-6 w-6 rounded-full border relative",class:{
            'border-appMain': _vm.value === item.value,
            'border-midnight': _vm.value !== item.value,
          }},[(!_vm.iconless && _vm.value === item.value)?_c('span',{staticClass:"w-4 h-4 rounded-full bg-appMain absolute inset-0 m-auto"}):_vm._e()]):_vm._e(),_c('div',{class:{
            'ml-4': !_vm.iconless,
            'flex justify-center w-full': _vm.iconless,
          }},[_c('span',{staticClass:"block text-midnight",class:{
              'mr-2 text-sm': _vm.tags,
              'text-base': !_vm.tags,
            }},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.safeTranslate(item.label))+" ")]},{"item":item})],2),(_vm.showDescription && item.description)?_c('span',{staticClass:"block text-sm text-towerGrey"},[_vm._v(" "+_vm._s(_vm.safeTranslate(item.description))+" ")]):_vm._e()])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }