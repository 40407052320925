
import { Component, Vue } from "vue-property-decorator";
import TheSidebarNavDesktop from "./TheSidebarNavDesktop.vue";
import Kam from "@/components/common/Kam.vue";
import { INavElements } from "@/models/Global";
import { NAV_MENU_HOME, NAV_MENU_BUSINESS, NAV_MENU_COMMON } from "@/constants";
import { mapGetters } from "vuex";

@Component({
  components: { TheSidebarNavDesktop, Kam },
  computed: {
    ...mapGetters("auth", {
      business: "getBusiness",
      useDashboard: "useDashboard",
    }),
  },
})
export default class TheSidebarDesktop extends Vue {
  protected business!: string[];
  protected useDashboard!: boolean;

  protected get navElements(): INavElements[] {
    const menu = [];

    if (this.useDashboard) menu.push(...NAV_MENU_HOME);
    menu.push(
      ...NAV_MENU_BUSINESS.filter((item: INavElements) =>
        this.business.includes(item.name)
      )
    );
    menu.push(...NAV_MENU_COMMON);

    return menu;
  }
}
