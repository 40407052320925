import HiboRequest from "./request";
import {
  IMortgageData,
  IMortgagesCallParams,
  IMortgagesDataList,
  IMortgagesUnreadMessages,
  INewMortgageRequest,
  INewMortgageResponse,
  IUploadedFile,
} from "@/models/Mortgages";
import { IDocument } from "@/models/Documents";
import { IEuribor, IMortgageQuota, IMortgageQuote } from "@/models/Simulator";
import StitchService from "@/services/StitchService";
export default class MortgagesApi {
  private request: HiboRequest;
  private baseUrl = "/hibo/v1";
  constructor(request: HiboRequest) {
    this.request = request;
  }

  async createMortgage(
    data: INewMortgageRequest
  ): Promise<INewMortgageResponse> {
    const url = `${this.baseUrl}/mortgages`;
    const response = await this.request.call<INewMortgageResponse>({
      method: "POST",
      url,
      data,
    });
    if (response.mortgageId)
      StitchService.trackEvent("lead", `MO_${response.mortgageId}`);
    return response;
  }

  getMortgage(id: string): Promise<IMortgageData> {
    const url = `hibo/v1/mortgages/${id}`;
    return this.request.call<IMortgageData>({ method: "GET", url });
  }

  getMortgages(params: IMortgagesCallParams): Promise<IMortgagesDataList> {
    const url = `${this.baseUrl}/mortgages`;
    return this.request.call<IMortgagesDataList>({
      method: "GET",
      url,
      params,
    });
  }

  getMortgageFiles(data: number): Promise<IDocument[]> {
    const url = `hibo/v1/mortgages/${data}/documents`;
    return this.request.call<IDocument[]>({ method: "GET", url });
  }

  uploadDocUrl(mortgageId: number, docId: number): string {
    return `${process.env.VUE_APP_HIBO_API_URL}/api${this.baseUrl}/mortgages/${mortgageId}/documents/${docId}/files`;
  }

  uploadFile(uploadUrl: string, file: any): Promise<IUploadedFile> {
    const fd = new FormData();
    fd.append("fileToUpload", file);
    return this.request.call<IUploadedFile>({
      method: "POST",
      url: uploadUrl,
      data: fd,
    });
  }

  removeDocument(
    mortgageId: number,
    docId: number,
    fileId: number
  ): Promise<boolean> {
    const url = `${this.baseUrl}/mortgages/${mortgageId}/documents/${docId}/files/${fileId}`;
    return this.request.call<boolean>({ method: "DELETE", url });
  }

  getMortgageQuota(data: IMortgageQuota): Promise<IMortgageQuote> {
    const url = `public/v1/mortgages/quota?locationId=${data.locationId}&price=${data.purchasePrice}&term=${data.term}&savings=${data.savings}&rate=${data.rate}&rateType=${data.rateType}`;
    return this.request.call<IMortgageQuote>({ method: "GET", url });
  }

  getUnreadMessagesMortgages(): Promise<IMortgagesUnreadMessages[]> {
    const url = `${this.baseUrl}/unread-messages`;
    return this.request.call<IMortgagesUnreadMessages[]>({
      method: "GET",
      url,
    });
  }

  updateIsReadMessage(mortgageIds: number[]) {
    const url = `${this.baseUrl}/mortgages/unread-messages`;
    this.request.call({
      method: "PATCH",
      url,
      data: {
        mortgageIds: mortgageIds,
      },
    });
  }

  updateExtraInfo(mortgageId: number, extraInfo: string): Promise<void> {
    const url = `${this.baseUrl}/mortgages/${mortgageId}`;
    return this.request.call({
      method: "PATCH",
      url,
      data: {
        extraInfo: extraInfo,
      },
    });
  }

  getEuribor(): Promise<IEuribor> {
    const url = `${this.baseUrl}/euribor`;
    return this.request.call<IEuribor>({ method: "GET", url });
  }
}
