
import { Component, Vue } from "vue-property-decorator";
import ProfileWidget from "@/components/widgets/ProfileWidget.vue";
import OperationsSearch from "@/components/common/OperationsSearch.vue";
import { IUser } from "@/models/User";
import { mapGetters } from "vuex";
import NotificationWidget from "@/components/widgets/NotificationWidget.vue";

@Component({
  components: { NotificationWidget, ProfileWidget, OperationsSearch },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      businessList: "getBusiness",
    }),
    ...mapGetters("mortgages", {
      unreadMessagesMortgages: "getUnreadMessagesMortgages",
    }),
  },
})
export default class TheHeader extends Vue {
  protected user!: IUser;
  protected businessList!: string[];
  protected unreadMessagesMortgages!: number[];

  get routeName(): string {
    return this.$route.name as string;
  }

  get headerStyles(): Record<string, string> {
    const style = {
      header: "bg-white",
      contentColor: "midnight",
    };

    switch (this.routeName) {
      case "simulator":
        style.header = "hidden";
        break;
      case "dashboard":
        style.header = "bg-midnight";
        style.contentColor = "white";
        break;
      default:
        break;
    }

    return style;
  }
}
