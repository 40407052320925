
import { Component, Vue, Prop } from "vue-property-decorator";
import { INavElements } from "@/models/Global";
import { TOOL_TABS } from "@/constants/tools";

@Component
export default class TheSidebarNavDesktop extends Vue {
  @Prop({ type: Array, required: true })
  protected navElements!: INavElements[];

  protected preventRedundantNavigation(urlName: string) {
    if (urlName !== this.$route.name) this.$router.push({ name: urlName });
  }

  protected isElementSelected(urlName: string) {
    if (urlName === "tools") {
      return TOOL_TABS.some((tab) => tab.routeName === this.$route.name);
    }
    return urlName === this.$route.name;
  }
}
