import * as Sentry from "@sentry/browser";
import { Route } from "vue-router";

interface IPageData {
  page: string;
  location: string;
}

export default class GoogleAnalyticsService {
  private currentRoute: Route | null = null;

  setRoute(route: Route | null) {
    this.currentRoute = route;
  }

  pageView(route: Route | null = this.currentRoute) {
    try {
      if (!window?.dataLayer || !route) return;
      const pageData = { page: route.path, location: route.path };
      this.trackPageview(pageData);
    } catch (err) {
      console.error("GoogleAnalyticsService pageView error");
      Sentry.captureException("GoogleAnalyticsService pageView error: " + err);
    }
  }

  trackEventGeneric(category: string, action = "", label = "") {
    this.trackObjective(category, action, label);
  }

  private trackObjective(
    category: string,
    action: string | null = null,
    label: string | null = null
  ) {
    try {
      gtagGA("hiboteca_event", {
        event_category: category,
        event_action: action,
        event_label: label,
      });
      this.debugObjective(category, action, label);
    } catch (err) {
      console.error("GoogleAnalyticsService trackObjective error");
      Sentry.captureException(
        "GoogleAnalyticsService trackObjective error: " + err
      );
    }
  }

  private debugObjective(
    category: string,
    action: string | null,
    label: string | null
  ) {
    try {
      if (process.env.ENVIRONMENT === "production") return;
      console.log(
        `[Google Analytics] TrackObjective ${category} ${action} ${label}`
      );
    } catch (err) {
      console.error("GoogleAnalyticsService debugObjective error");
      Sentry.captureException(
        "GoogleAnalyticsService debugObjective error: " + err
      );
    }
  }

  private trackPageview(pageData: IPageData) {
    try {
      this.debugPageview(pageData);
      gtagGA("hiboteca_pageview", pageData);
    } catch (err) {
      console.error("GoogleAnalyticsService trackObjective error");
      Sentry.captureException(
        "GoogleAnalyticsService trackObjective error: " + err
      );
    }
  }

  private debugPageview(pageData: IPageData) {
    try {
      if (process.env.ENVIRONMENT === "production") return;
      console.log(
        `[Google Analytics] trackPageview ${pageData.location} ${pageData.page}`
      );
    } catch (err) {
      console.error("GoogleAnalyticsService debugPageView error");
      Sentry.captureException(
        "GoogleAnalyticsService debugPageView error: " + err
      );
    }
  }
}

function gtagGA(type: string, eventData: IPageData | any) {
  if (!window.dataLayer) window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ...eventData, event: type });
}
