var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.withLabel || _vm.with2labels)?_c('div',{staticClass:"flex items-center"},[_c('label',{staticClass:"block mb-5 text-smaltBlue text-base 2xl:text-lg"},[_vm._v(" "+_vm._s(_vm.withConfirmation ? _vm.$t("newPassword") : _vm.$t("password"))+" ")]),_c('HiboSvg',{staticClass:"cursor-pointer ml-10",class:_vm.showPassword1 ? 'text-persimmon' : 'text-smaltBlue',attrs:{"svg":_vm.showPassword1 ? 'icons/eye' : 'icons/eyeClosed',"svg-class":"w-25"},on:{"click":function($event){_vm.showPassword1 = !_vm.showPassword1}}})],1):_vm._e(),_c('HiboInput',{attrs:{"placeholder":_vm.withConfirmation ? _vm.$t('newPassword') : _vm.$t('password'),"icon":"icons/lock","data-qa":"input-password","type":_vm.showPassword1 ? 'text' : 'password',"error-text":_vm.$v.password.$error || (_vm.showAllFormErrors && _vm.$v.password.$invalid)
          ? _vm.$t('requiredField')
          : null},on:{"input":_vm.handleChange,"enter":function($event){return _vm.$emit('enter')}},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}})],1),(_vm.withConfirmation)?_c('div',{class:("mt-" + _vm.inputsSpacing)},[(_vm.with2labels)?_c('div',{staticClass:"flex items-center"},[_c('label',{staticClass:"block mb-5 text-smaltBlue text-base 2xl:text-lg"},[_vm._v(" "+_vm._s(_vm.$t("repeatPassword"))+" ")]),_c('HiboSvg',{staticClass:"cursor-pointer ml-10",class:_vm.showPassword2 ? 'text-persimmon' : 'text-smaltBlue',attrs:{"svg":_vm.showPassword2 ? 'icons/eye' : 'icons/eyeClosed',"svg-class":"w-25"},on:{"click":function($event){_vm.showPassword2 = !_vm.showPassword2}}})],1):_vm._e(),_c('HiboInput',{attrs:{"icon":"icons/lock","type":_vm.showPassword2 ? 'text' : 'password',"data-qa":"input-pwdconfirmation","placeholder":_vm.$t('repeatPassword'),"error-text":_vm.$v.passwordConfirmation.$error ||
        (_vm.showAllFormErrors &&
          (_vm.$v.passwordConfirmation.$invalid ||
            !_vm.$v.passwordConfirmation.$model))
          ? _vm.$t('mustBeEqualToPassword')
          : null},on:{"input":_vm.handleChange,"enter":function($event){return _vm.$emit('enter')}},model:{value:(_vm.$v.passwordConfirmation.$model),callback:function ($$v) {_vm.$set(_vm.$v.passwordConfirmation, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.passwordConfirmation.$model"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }